import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import './style.scss';
interface Props {
  show: boolean;
  children: React.ReactNode;
  title: string;
  cancelText: string;
  confirmText: string;
  onConfirm: () => void;
  onCancel?: () => void;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
}

function AppModal({ setShow, show, children, title, cancelText, confirmText, onConfirm, onCancel }: Props) {
  const { t } = useTranslation();

  const handleConfirm = () => {
    setShow && setShow(false);
    onConfirm();
  };
  const handleCancel = () => {
    setShow && setShow(false);
    onCancel && onCancel();
  };
  return (
    <>
      <Modal show={show} onHide={handleCancel} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <b className="text-blue-links">{title}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" className="btn-links-outline" onClick={handleCancel}>
            {cancelText || t('common.cancel')}
          </Button>
          <Button variant="danger" className="px-4" onClick={handleConfirm}>
            {confirmText || t('common.confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AppModal;
