import { ExclamationCircleOutlined } from '@ant-design/icons';
import { InputNumber, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { formatCurrency, getFormattedDateHeader } from '../../../../../../shared/utils/common.utils';
import { BaselineOffertaDto, RevenueMonthDto } from '@api/client';
import { useAppDispatch } from '../../../../../../core/store/hooks';
import { saveRevenuesToBeConsolidated } from '../../_redux/actions';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  ricaviMeseDaConsolidare: RevenueMonthDto | undefined;
  baselineOfferta: BaselineOffertaDto | undefined;
  revenueMonthToBeConsolidated: number | undefined;
  setRevenueMonthToBeConsolidated: Dispatch<SetStateAction<number | undefined>>;
}

const MonthlyRevenuesInput: React.FC<Props> = ({
  ricaviMeseDaConsolidare,
  baselineOfferta,
  revenueMonthToBeConsolidated,
  setRevenueMonthToBeConsolidated,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const setRevenueDaConsolidare = (value: number) => {
    dispatch(saveRevenuesToBeConsolidated(value || 0));
    setRevenueMonthToBeConsolidated(value || 0);
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <span className="mx-1 notification-header">
          {`${t('project-detail.revenues')} ${getFormattedDateHeader(
            ricaviMeseDaConsolidare?.month,
            ricaviMeseDaConsolidare?.year
          )} (€) :`}
        </span>
        <Tooltip
          title={
            (baselineOfferta?.ricaviConsolidati || 0) + (revenueMonthToBeConsolidated || 0) >
            (baselineOfferta?.ricaviTotali || 0)
              ? t('cdg.table-cdg.validation-revenues-tooltip', {
                  amount: formatCurrency(
                    (baselineOfferta?.ricaviConsolidati || 0) +
                      (Number(revenueMonthToBeConsolidated) || 0) -
                      (baselineOfferta?.ricaviTotali || 0)
                  ),
                })
              : ``
          }
          color="red"
        >
          <InputNumber
            style={{ width: '140px' }}
            decimalSeparator=","
            step={0.01}
            min={0}
            keyboard={false}
            disabled={ricaviMeseDaConsolidare?.editable === false}
            value={revenueMonthToBeConsolidated}
            onChange={(e) => setRevenueDaConsolidare(e || 0)}
            placeholder={t('project-detail.revenues-tooltip')}
            suffix={
              <Tooltip title={`text`}>
                <ExclamationCircleOutlined
                  className="pe-3"
                  style={{
                    color:
                      (baselineOfferta?.ricaviConsolidati || 0) + (revenueMonthToBeConsolidated || 0) >
                      (baselineOfferta?.ricaviTotali || 0)
                        ? 'red'
                        : 'transparent',
                  }}
                />
              </Tooltip>
            }
          />
        </Tooltip>
      </div>
      {(baselineOfferta?.ricaviConsolidati || 0) > (baselineOfferta?.ricaviTotali || 0) && (
        <div className="ms-3" style={{ maxWidth: '250px' }}>
          <span className="revenues-exceeded-span text-red">
            {t('cdg.table-cdg.revenues-exceded', {
              amount: formatCurrency((baselineOfferta?.ricaviConsolidati || 0) - (baselineOfferta?.ricaviTotali || 0)),
            })}
          </span>
        </div>
      )}
    </>
  );
};

export default MonthlyRevenuesInput;
