import { useState } from 'react';

const useHandleVisibleColumns = () => {
  const [visibleColumns, setVisibleColumns] = useState<{ [key: string]: boolean }>(() => {
    const savedColumns = localStorage.getItem('visibleColumns');
    return savedColumns
      ? JSON.parse(savedColumns)
      : {
          ppsProfile: true,
          pptSeniority: true,
          name: true,
          guRemaining: true,
          currentMonthRendicontations: true,
          worked: true,
          totalPlanned: true,
        };
  });

  return { visibleColumns, setVisibleColumns };
};

export default useHandleVisibleColumns;
