export enum ProjectLabels {
  ID = 'ID',
  PROJECT_NAME = 'Nome Progetto',
  CUSTOMER = 'Nome cliente',
  BUSINESS_MANAGER = 'Business Manager',
  STATUS = 'Stato',
  DATE_LAST_MODIFY = 'Data ultima modifica',
  ACTION = 'Azione',
}

export enum Actions {
  VISUALIZZA = 'Visualizza',
  VALIDA = 'Valida',
  MODIFICA = 'Modifica',
}

export enum TableProjectKeys {
  TEAM = 'team',
  NOTES = 'notes',
  BILLING = 'billing',
  EXTERNAL_COSTS = 'external-costs',
  OVERALL_DATA = 'overall-data',
  CURRENT_YEAR_DATA = 'current-year-data',
}

export enum Session {
  CDG = 'cdg',
  CL = 'cl',
  PL = 'pl',
}

export enum CrudMenuKeys {
  PROJECT_CRUD = 'project-crud',
  REVENUES_COSTS_CRUD = 'revenues-costs-crud',
  PROJECT_CRUD_DELIVERY = 'project-crud-delivery',
  STRUCTURE_CRUD_DELIVERY = 'structure-crud-delivery',
  STRUCTURE_CRUD_BUSINESS = 'structure-crud-business',
}

export enum ManagementToolsTabs {
  SENDING_PROGRESS_DATA = 'sending-progress-data',
  REFRESH_HR_COSTS = 'refresh-hr-costs',
}

export enum DashboardTabs {
  PROJECTS_TABLE_CURRENT_YEAR = 'projects-table-current-year',
  PROJECTS_TABLE = 'projects-table',
  PEOPLE_TABLE = 'people-table',
  UNDERBOOKING_TABLE = 'underbooking-table',
  OVERBOOKING_TABLE = 'overbooking-table',
}

export enum DashboardProjectsPeriod {
  ALL = 'all',
  CURRENT_YEAR = 'current-year',
}

export enum PassiveCycleTabs {
  ORDERS = 'orders',
  SUPPLIERS_CRUD = 'suppliers-crud',
}
