import { FornitoreDto } from '@api/client';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../core/store/hooks';
import { getSupplierById, getSuppliers } from '../../_redux/actions';
import { suppliersSelector } from '../../_redux/selectors';
import { StorageKeysEnum } from '../../../../../enums/storageKeys.enum';

const useGetSuppliers = () => {
  const dispatch = useAppDispatch();
  const suppliers = useAppSelector(suppliersSelector);
  const [supplierList, setSupplierList] = useState<FornitoreDto[]>(suppliers);
  const [allSuppliers, setAllSuppliers] = useState<FornitoreDto[]>([]);
  const [searchedSupplier, setSearchedSupplier] = useState<number | undefined>(undefined);

  useEffect(() => {
    dispatch(getSuppliers())
      .unwrap()
      .then((res) => {
        if (res) {
          setAllSuppliers(res);
        }
      });
  }, []);

  useEffect(() => {
    setSupplierList(suppliers);
    sessionStorage.setItem(StorageKeysEnum.CURRENT_PAGE_SUPPLIERS, String(0));
  }, [suppliers]);

  const searchSupplier = () => {
    if (searchedSupplier) {
      dispatch(getSupplierById(searchedSupplier));
    } else {
      dispatch(getSuppliers());
    }
  };

  return {
    supplierList,
    searchedSupplier,
    setSearchedSupplier,
    searchSupplier,
    allSuppliers,
    setAllSuppliers,
  };
};

export default useGetSuppliers;
