import { Col, Input, Row } from 'antd';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import LinksButton from '../../../../../shared/design-system/components/links-button';
import { FornitoreDto } from '@api/client';
import { Dispatch, SetStateAction } from 'react';
import { useAppDispatch } from '../../../../../core/store/hooks';
import { createSupplier, getSupplierById, updateSupplier } from '../../_redux/actions';
import { Button } from 'react-bootstrap';
import { AppToastService } from '../../../../../shared/design-system/components/app-toast/app-toast.service';

interface Props {
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  supplier: FornitoreDto | undefined;
  setSupplier: Dispatch<SetStateAction<FornitoreDto | undefined>>;
}

const SuppliersCrudForm: React.FC<Props> = ({ editMode, setEditMode, supplier, setSupplier }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleSubmit = (values: FornitoreDto, { resetForm }: any) => {
    if (editMode) {
      dispatch(updateSupplier({ fornitoreDto: values }))
        .unwrap()
        .then((res) => {
          if (res) {
            setEditMode(false);
            setSupplier(undefined);
            dispatch(getSupplierById(values.id!));
            AppToastService.success(`Supplier ${values.denominazione} updated successfully`);
            resetForm();
          }
        });
    } else {
      dispatch(createSupplier({ fornitoreDto: values }))
        .unwrap()
        .then((res) => {
          if (res) {
            setSupplier(undefined);
            dispatch(getSupplierById(res.id!));
            AppToastService.success(`Supplier ${values.denominazione} created successfully`);
            resetForm();
          }
        });
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        id: supplier?.id || undefined,
        denominazione: supplier?.denominazione || undefined,
      }}
      enableReinitialize
    >
      {({ handleSubmit: _handleSubmit, handleChange, setFieldValue, values, touched, errors, resetForm }) => {
        return (
          <form onSubmit={_handleSubmit}>
            <Row>
              <Col lg={6} md={9} sm={12} className="me-3">
                <div className="form-group">
                  <label htmlFor="id">{t('ID')}</label>
                  <Input
                    required
                    allowClear
                    placeholder={t('Insert ID')}
                    type="text"
                    id="id"
                    name="id"
                    size="large"
                    value={values.id}
                    onChange={handleChange}
                    disabled={editMode}
                  />
                </div>
              </Col>
              <Col lg={6} md={9} sm={12} className="me-3">
                <div className="form-group">
                  <label htmlFor="denominazione">{t('Name')}</label>
                  <Input
                    required
                    allowClear
                    placeholder={t('Insert name')}
                    type="text"
                    id="denominazione"
                    name="denominazione"
                    size="large"
                    value={values.denominazione}
                    onChange={handleChange}
                  />
                </div>
              </Col>

              <Col className="align-self-end mb-1" lg={6} md={9} sm={12} xs={12}>
                <LinksButton type="submit" className="btn btn-links" isDisabled={!values.id || !values.denominazione}>
                  {editMode ? t('Update') : t('Create')}
                </LinksButton>
                {editMode && (
                  <Button
                    className="btn text-danger trash-btn"
                    type="button"
                    variant="icon"
                    onClick={() => {
                      setEditMode(false);
                      setSupplier(undefined);
                    }}
                  >
                    <i className="fa-regular fa-trash-can me-2"></i>
                    {t('Exit edit mode')}
                  </Button>
                )}
              </Col>
            </Row>
          </form>
        );
      }}
    </Formik>
  );
};

export default SuppliersCrudForm;
