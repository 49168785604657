import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { LocationFilterEnum } from '../../../../enums/global.enum';
import AppAccordion from '../../../../shared/design-system/components/app-accordion';
import AppCard from '../../../../shared/design-system/components/app-card';
import NoData from '../../../../shared/design-system/components/no-data-box';
import AppPaginator from '../../../../shared/design-system/components/app-paginator';
import { StorageKeysEnum } from '../../../../enums/storageKeys.enum';
import LinksButton from '../../../../shared/design-system/components/links-button';
import ModalAction from '../../project-list/project-detail/_components/modal-action';
import useFormPassiveCycle from '../../../../shared/design-system/components/app-accordion/_hooks/useFormPassiveCycle';
import useGetAllUsers from '../../../../shared/_hooks/useGetAllUsers';
import usePagination from '../../../../core/hooks/usePagination';
import useGetAndMapOrders from './_hooks/useGetAndMapOrders';
import useGetPassiveCycleOptions from '../_hooks/useGetPassiveCycleOptions';
import FiltersOrders, { orderListFormData } from '../_components/filters-passive-cycle';
import useDeleteOrderById from './_hooks/useDeleteOrderById';
import AddOrderModal from './_components/add-order-modal';
import OrderListTable from './_components/order-list-table';

const OrderList: React.FC = () => {
  const { t } = useTranslation();
  const { allUsers } = useGetAllUsers();
  const { orders } = useGetAndMapOrders();
  const { mappedPassiveCycleOptions } = useGetPassiveCycleOptions();
  const [showAddOrderModal, setShowAddOrderModal] = useState<boolean>(false);
  const [showConfirmDeleteOrderModal, setShowConfirmDeleteOrderModal] = useState<boolean>(false);
  const {
    pageNumber: pageNumberOrder,
    setPageNumber: setPageNumberOrder,
    totalPages: totalPagesOrder,
    visibleItems: visibleOrders,
  } = usePagination(orders, 10);

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { onSubmit, onReset, chipsFormData, setChipsFormData, reset, filterParams } = useFormPassiveCycle(
    orderListFormData,
    setIsOpen
  );

  const [controlledDate, setControlledDate] = useState<any>(null);

  const handleReset = () => {
    onReset();
    controlledDate && setControlledDate([]);
    chipsFormData && setChipsFormData([]);
  };

  const { setIdToDelete, deleteOrderById } = useDeleteOrderById();

  const showModalDeleteOrder = (id: number) => {
    setIdToDelete(id);
    setShowConfirmDeleteOrderModal(true);
  };

  return (
    <>
      <div className="d-flex justify-content-between mt-3">
        <h4 className="text-gray-800 my-0">{t('Passive cycle orders')}</h4>
        <div>
          <LinksButton className="btn-sm btn-primary btn-teal px-4 ms-auto" onClick={() => setShowAddOrderModal(true)}>
            {t('+ Add order')}
          </LinksButton>
        </div>
      </div>

      <AppAccordion
        formData={orderListFormData}
        location={LocationFilterEnum.PASSIVE_CYCLE}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        chipsFormData={chipsFormData}
        setChipsFormData={setChipsFormData}
        reset={reset}
      >
        <FiltersOrders
          onSubmit={onSubmit}
          handleReset={handleReset}
          setControlledDate={setControlledDate}
          mappedPassiveCycleOptions={mappedPassiveCycleOptions}
          // onReset={onReset} control={control} register={register}
        />
      </AppAccordion>

      <AppCard title={t('All orders')} className="mt-3">
        {orders.length === 0 ? (
          <NoData msg={t('No orders found')} />
        ) : (
          <>
            <OrderListTable data={visibleOrders} showModalDeleteOrder={showModalDeleteOrder} />
          </>
        )}
        {visibleOrders?.length > 0 ? (
          <AppPaginator
            keyPaginator={StorageKeysEnum.CURRENT_PAGE_ORDERS}
            currentPage={pageNumberOrder}
            totalPages={totalPagesOrder}
            totalElements={orders.length}
            pageSize={10}
            onPageChange={setPageNumberOrder}
          />
        ) : (
          ''
        )}
      </AppCard>
      <AddOrderModal
        show={showAddOrderModal}
        setShow={setShowAddOrderModal}
        mappedPassiveCycleOptions={mappedPassiveCycleOptions}
        allUsers={allUsers}
      />
      <ModalAction
        title={t('Delete order')}
        actionLabel={t('common.confirm')}
        cancelLabel={t('common.cancel')}
        action={() => {
          deleteOrderById();
        }}
        setShow={(value) => setShowConfirmDeleteOrderModal(value)}
        show={showConfirmDeleteOrderModal}
      >
        <span className="text-danger">
          <i className="fa-solid fa-triangle-exclamation"></i> <b>{t('common.attention')}</b>{' '}
        </span>
        <div className="p-1">
          <p>
            {t('This action will delete the contract and all related associations. Are you sure you want to continue?')}
          </p>
        </div>
      </ModalAction>
    </>
  );
};

export default OrderList;
