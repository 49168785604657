/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AziendaFromJSON, AziendaToJSON, } from './Azienda';
import { FornitoreFromJSON, FornitoreToJSON, } from './Fornitore';
import { OrderCategoryFromJSON, OrderCategoryToJSON, } from './OrderCategory';
import { OrderContractStatusFromJSON, OrderContractStatusToJSON, } from './OrderContractStatus';
import { OrderExpenseItemFromJSON, OrderExpenseItemToJSON, } from './OrderExpenseItem';
import { OrderProcessingStatusFromJSON, OrderProcessingStatusToJSON, } from './OrderProcessingStatus';
import { OrderTypeFromJSON, OrderTypeToJSON, } from './OrderType';
import { ProjectExternalCostFromJSON, ProjectExternalCostToJSON, } from './ProjectExternalCost';
import { SedeFromJSON, SedeToJSON, } from './Sede';
/**
 * Check if a given object implements the ContractOrderOutputDto interface.
 */
export function instanceOfContractOrderOutputDto(value) {
    return true;
}
export function ContractOrderOutputDtoFromJSON(json) {
    return ContractOrderOutputDtoFromJSONTyped(json, false);
}
export function ContractOrderOutputDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'contractStatus': json['contractStatus'] == null ? undefined : OrderContractStatusFromJSON(json['contractStatus']),
        'processingStatus': json['processingStatus'] == null ? undefined : OrderProcessingStatusFromJSON(json['processingStatus']),
        'orderType': json['orderType'] == null ? undefined : OrderTypeFromJSON(json['orderType']),
        'contractValue': json['contractValue'] == null ? undefined : json['contractValue'],
        'resaleProjectCode': json['resaleProjectCode'] == null ? undefined : json['resaleProjectCode'],
        'resaleDetachmentRate': json['resaleDetachmentRate'] == null ? undefined : json['resaleDetachmentRate'],
        'automaticRenewal': json['automaticRenewal'] == null ? undefined : json['automaticRenewal'],
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'signatureDate': json['signatureDate'] == null ? undefined : (new Date(json['signatureDate'])),
        'creationDate': json['creationDate'] == null ? undefined : (new Date(json['creationDate'])),
        'lastmodifiedDate': json['lastmodifiedDate'] == null ? undefined : (new Date(json['lastmodifiedDate'])),
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'modifiedBy': json['modifiedBy'] == null ? undefined : json['modifiedBy'],
        'projectExternalCost': json['projectExternalCost'] == null ? undefined : (json['projectExternalCost'].map(ProjectExternalCostFromJSON)),
        'expenseItems': json['expenseItems'] == null ? undefined : (json['expenseItems'].map(OrderExpenseItemFromJSON)),
        'remainingBalance': json['remainingBalance'] == null ? undefined : json['remainingBalance'],
        'note': json['note'] == null ? undefined : json['note'],
        'isDeleted': json['isDeleted'] == null ? undefined : json['isDeleted'],
        'idFornitore': json['idFornitore'] == null ? undefined : FornitoreFromJSON(json['idFornitore']),
        'linkDocumentation': json['linkDocumentation'] == null ? undefined : json['linkDocumentation'],
        'category': json['category'] == null ? undefined : OrderCategoryFromJSON(json['category']),
        'legalEntity': json['legalEntity'] == null ? undefined : AziendaFromJSON(json['legalEntity']),
        'cancellationWithin': json['cancellationWithin'] == null ? undefined : (new Date(json['cancellationWithin'])),
        'cancelAtAnyTime': json['cancelAtAnyTime'] == null ? undefined : json['cancelAtAnyTime'],
        'withdrawalWithin': json['withdrawalWithin'] == null ? undefined : (new Date(json['withdrawalWithin'])),
        'cancellationProcedure': json['cancellationProcedure'] == null ? undefined : json['cancellationProcedure'],
        'object': json['object'] == null ? undefined : json['object'],
        'withdrawalWithinLiteral': json['withdrawalWithinLiteral'] == null ? undefined : json['withdrawalWithinLiteral'],
        'idExternalContract': json['idExternalContract'] == null ? undefined : json['idExternalContract'],
        'fkUtente': json['fkUtente'] == null ? undefined : json['fkUtente'],
        'idSede': json['idSede'] == null ? undefined : SedeFromJSON(json['idSede']),
        'linkTicket': json['linkTicket'] == null ? undefined : json['linkTicket'],
    };
}
export function ContractOrderOutputDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'contractStatus': OrderContractStatusToJSON(value['contractStatus']),
        'processingStatus': OrderProcessingStatusToJSON(value['processingStatus']),
        'orderType': OrderTypeToJSON(value['orderType']),
        'contractValue': value['contractValue'],
        'resaleProjectCode': value['resaleProjectCode'],
        'resaleDetachmentRate': value['resaleDetachmentRate'],
        'automaticRenewal': value['automaticRenewal'],
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString()),
        'signatureDate': value['signatureDate'] == null ? undefined : ((value['signatureDate']).toISOString()),
        'creationDate': value['creationDate'] == null ? undefined : ((value['creationDate']).toISOString()),
        'lastmodifiedDate': value['lastmodifiedDate'] == null ? undefined : ((value['lastmodifiedDate']).toISOString()),
        'createdBy': value['createdBy'],
        'modifiedBy': value['modifiedBy'],
        'projectExternalCost': value['projectExternalCost'] == null ? undefined : (value['projectExternalCost'].map(ProjectExternalCostToJSON)),
        'expenseItems': value['expenseItems'] == null ? undefined : (value['expenseItems'].map(OrderExpenseItemToJSON)),
        'remainingBalance': value['remainingBalance'],
        'note': value['note'],
        'isDeleted': value['isDeleted'],
        'idFornitore': FornitoreToJSON(value['idFornitore']),
        'linkDocumentation': value['linkDocumentation'],
        'category': OrderCategoryToJSON(value['category']),
        'legalEntity': AziendaToJSON(value['legalEntity']),
        'cancellationWithin': value['cancellationWithin'] == null ? undefined : ((value['cancellationWithin']).toISOString()),
        'cancelAtAnyTime': value['cancelAtAnyTime'],
        'withdrawalWithin': value['withdrawalWithin'] == null ? undefined : ((value['withdrawalWithin']).toISOString()),
        'cancellationProcedure': value['cancellationProcedure'],
        'object': value['object'],
        'withdrawalWithinLiteral': value['withdrawalWithinLiteral'],
        'idExternalContract': value['idExternalContract'],
        'fkUtente': value['fkUtente'],
        'idSede': SedeToJSON(value['idSede']),
        'linkTicket': value['linkTicket'],
    };
}
