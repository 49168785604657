import { StaffExtendedDto, UserDto } from '@api/client';
import { useState } from 'react';

const useMoveDays = (
  collaborators: UserDto[] | null | undefined,
  currentCollaborator: Partial<StaffExtendedDto> | null | undefined,
  fullAllocations: Partial<StaffExtendedDto>[] | null | undefined,
  setFullAllocations: React.Dispatch<React.SetStateAction<Partial<StaffExtendedDto>[] | null | undefined>>,
  filterAllocationsByYear: (year: any, allocations: Partial<StaffExtendedDto>[]) => void,
  setDraftValidation: React.Dispatch<React.SetStateAction<boolean>>,
  setShowModalMoveDays: React.Dispatch<React.SetStateAction<boolean>>,
  currentYear: number | 'all'
) => {
  const [newCollaboratorPPS, setNewCollaboratorPPS] = useState<string | undefined>(undefined);
  const [newCollaboratorPPT, setNewCollaboratorPPT] = useState<string | undefined>(undefined);
  const [newCollaboratorName, setNewCollaboratorName] = useState<string | undefined>(undefined);
  const [newCollaborator, setNewCollaborator] = useState<boolean | undefined>(false);

  const onChangeSwitch = (checked: boolean) => {
    setNewCollaborator(checked);
  };

  const handleClearPps = () => {
    setNewCollaboratorPPS(undefined);
    setNewCollaboratorPPT(undefined);
    setNewCollaboratorName(undefined);
  };
  const handleChangeNewProfile = (value: any, field: 'Pps' | 'Ppt') => {
    if (field === 'Pps') {
      setNewCollaboratorPPS(value);
      setNewCollaboratorPPT(undefined);
      setNewCollaboratorName(undefined);
    } else if (field === 'Ppt') {
      setNewCollaboratorPPT(value);
      setNewCollaboratorName(undefined);
    }
  };

  const handleChangeModalCollaborator = (e: any) => {
    //set parametri per nuovo collab
    if (!e) {
      setNewCollaboratorName(undefined);
      return;
    }
    const targetCollaborator = collaborators?.find((el) => el.id === e);
    const idPps = targetCollaborator?.idPps ? String(targetCollaborator.idPps) : undefined;
    const idPpt = targetCollaborator?.idPpt ? String(targetCollaborator.idPpt) : undefined;
    const name = targetCollaborator?.nominativo;

    const newCollaborator = {
      userId: e && String(e),
      name: name,
      idPps: String(idPps),
      idPpt: String(idPpt),
    };

    setNewCollaboratorName(newCollaborator.name);
    setNewCollaboratorPPS(newCollaborator.idPps);
    setNewCollaboratorPPT(newCollaborator.idPpt);
  };

  const moveDaysToNewCollaborator = () => {
    if (!newCollaboratorPPT || !newCollaboratorName) {
      console.error('Missing required fields: newCollaboratorPPT or newCollaboratorName');
      return;
    }

    const newCollaborator = {
      idPps: newCollaboratorPPS,
      idPpt: newCollaboratorPPT,
      userId: String(collaborators?.find((el) => el.nominativo === newCollaboratorName)?.id) || 'unknown',
      name: newCollaboratorName,
      allocazioni:
        currentCollaborator?.allocazioni?.map((allocation: any) => {
          if (allocation?.editEnable) {
            return {
              ...allocation,
              id: allocation.id + 1,
            };
          } else
            return {
              ...allocation,
              actualAllocation: 0,
              id: allocation.id + 1,
            };
        }) ?? [],
    };

    let newFullAllocations = fullAllocations?.map((el) => {
      if (el.userId === currentCollaborator?.userId) {
        return {
          ...el,
          allocazioni: el.allocazioni?.map((alloc) => {
            if (alloc.editEnable) {
              return {
                ...alloc,
                actualAllocation: 0,
              };
            }
            return alloc;
          }),
        };
      }
      handleClearPps();
      return el;
    });

    const newAllocations = [newCollaborator, ...(newFullAllocations ?? [])];
    setFullAllocations(newAllocations);
    filterAllocationsByYear(currentYear, newAllocations);
    setDraftValidation(false);
    setShowModalMoveDays(false);
  };
  const [collaboratorReceiver, setCollaboratorReceiver] = useState<any>(undefined);
  const handleSelectChange = (value: any) => {
    setCollaboratorReceiver(value);
  };

  const transferDaysById = (receiverUserId: string) => {
    const updatedAllocations = fullAllocations?.map((collaborator) => {
      if (collaborator.userId === receiverUserId) {
        const newAllocations: any = [
          ...(collaborator?.allocazioni?.filter((alloc: any) => !alloc.editEnable) ?? []),
          ...currentCollaborator!.allocazioni!.filter((alloc: any) => alloc.editEnable),
        ];
        return {
          ...collaborator,
          allocazioni: newAllocations,
        };
      }
      return collaborator;
    });

    // per pulire allocazioni currentCollaborator
    const finalAllocations = updatedAllocations?.map((collaborator) => {
      if (collaborator.userId === currentCollaborator?.userId) {
        const clearedAllocations = collaborator.allocazioni.map((alloc: any) => ({
          ...alloc,
          actualAllocation: alloc.editEnable ? 0 : alloc.actualAllocation,
        }));
        return {
          ...collaborator,
          allocazioni: clearedAllocations,
        };
      }
      return collaborator;
    });

    setFullAllocations(finalAllocations);
    filterAllocationsByYear(currentYear, finalAllocations!);
  };

  const moveDaysToExistingCollaborator = () => {
    transferDaysById(collaboratorReceiver);
    handleClearPps();
    setNewCollaborator(undefined);
  };
  const moveDaysProps = {
    onChangeSwitch,
    handleClearPps,
    handleChangeNewProfile,
    handleChangeModalCollaborator,
    moveDaysToNewCollaborator,
    handleSelectChange,
    moveDaysToExistingCollaborator,
    newCollaborator,
    newCollaboratorPPS,
    newCollaboratorPPT,
    newCollaboratorName,
    collaboratorReceiver,
    setNewCollaborator,
  };

  return {
    moveDaysProps,
    moveDaysToNewCollaborator,
    moveDaysToExistingCollaborator,
    newCollaborator,
    setNewCollaborator,
  };
};

export default useMoveDays;
