/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExternalCostFromJSON, ExternalCostToJSON, } from './ExternalCost';
/**
 * Check if a given object implements the OrderExternalCost interface.
 */
export function instanceOfOrderExternalCost(value) {
    return true;
}
export function OrderExternalCostFromJSON(json) {
    return OrderExternalCostFromJSONTyped(json, false);
}
export function OrderExternalCostFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'groupId': json['groupId'] == null ? undefined : json['groupId'],
        'quoteId': json['quoteId'] == null ? undefined : json['quoteId'],
        'costTypeId': json['costTypeId'] == null ? undefined : json['costTypeId'],
        'amount': json['amount'] == null ? undefined : json['amount'],
        'creationDate': json['creationDate'] == null ? undefined : (new Date(json['creationDate'])),
        'lastmodifiedDate': json['lastmodifiedDate'] == null ? undefined : (new Date(json['lastmodifiedDate'])),
        'externalCosts': json['externalCosts'] == null ? undefined : (json['externalCosts'].map(ExternalCostFromJSON)),
    };
}
export function OrderExternalCostToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'groupId': value['groupId'],
        'quoteId': value['quoteId'],
        'costTypeId': value['costTypeId'],
        'amount': value['amount'],
        'creationDate': value['creationDate'] == null ? undefined : ((value['creationDate']).toISOString()),
        'lastmodifiedDate': value['lastmodifiedDate'] == null ? undefined : ((value['lastmodifiedDate']).toISOString()),
        'externalCosts': value['externalCosts'] == null ? undefined : (value['externalCosts'].map(ExternalCostToJSON)),
    };
}
