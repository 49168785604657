import { ExternalCostDto } from '@api/client';
import { useEffect } from 'react';

const useValidationDescriptionCosts = ({
  otherCostsTableData,
  setCostDescriptionValidation,
}: {
  otherCostsTableData: ExternalCostDto[] | null | undefined;
  setCostDescriptionValidation: (value: boolean) => void;
}) => {
  useEffect(() => {
    if (otherCostsTableData) {
      const validation = otherCostsTableData
        .filter((el) => el.costType?.duplicable)
        .every((cost) => !!cost.description);
      setCostDescriptionValidation(validation);
    }
  }, [otherCostsTableData]);
};

export default useValidationDescriptionCosts;
