import {
  Azienda,
  CicloPassivoFieldsDtoExpenseItemEnumListEnum,
  CicloPassivoFieldsDtoOrderContractStatusEnumListEnum,
  CicloPassivoFieldsDtoOrderProcessingStatusEnumListEnum,
  CicloPassivoFieldsDtoOrderTypeEnumListEnum,
  Fornitore,
  OrderCategory,
  Sede,
} from '@api/client';
import i18n from '../i18n/i18n';

export interface MappedOrderOptions {
  mappedExpenseItemEnumList: OrderOption[] | undefined;
  mappedOrderContractStatusEnumList: OrderOption[] | undefined;
  mappedOrderProcessingStatusEnumList: OrderOption[] | undefined;
  mappedOrderTypeEnumList: OrderOption[] | undefined;
  fornitoriList: Fornitore[] | undefined;
  orderCategoryList: OrderCategory[] | undefined;
  aziendaList: Azienda[] | undefined;
  sedeList: Sede[] | undefined;
}

export interface OrderOption {
  value: string | undefined;
  label: string | undefined;
}

export const mappedExpenseItemEnumList: OrderOption[] = [
  {
    value: CicloPassivoFieldsDtoExpenseItemEnumListEnum.Consulenze,
    label: i18n.t('passive-cycle.options.expense-items.consulting'),
  },
  {
    value: CicloPassivoFieldsDtoExpenseItemEnumListEnum.Distacco,
    label: i18n.t('passive-cycle.options.expense-items.secondment'),
  },
  {
    value: CicloPassivoFieldsDtoExpenseItemEnumListEnum.CostiPerLaRivendita,
    label: i18n.t('passive-cycle.options.expense-items.resale-costs'),
  },
  {
    value: CicloPassivoFieldsDtoExpenseItemEnumListEnum.Formazione,
    label: i18n.t('passive-cycle.options.expense-items.training'),
  },
  {
    value: CicloPassivoFieldsDtoExpenseItemEnumListEnum.GovernanceEControllo,
    label: i18n.t('passive-cycle.options.expense-items.gov'),
  },
  {
    value: CicloPassivoFieldsDtoExpenseItemEnumListEnum.Ict,
    label: i18n.t('passive-cycle.options.expense-items.ict'),
  },
  {
    value: CicloPassivoFieldsDtoExpenseItemEnumListEnum.Marketing,
    label: i18n.t('passive-cycle.options.expense-items.marketing'),
  },
  {
    value: CicloPassivoFieldsDtoExpenseItemEnumListEnum.ServiziProfessionali,
    label: i18n.t('passive-cycle.options.expense-items.professional-services'),
  },
  {
    value: CicloPassivoFieldsDtoExpenseItemEnumListEnum.Uffici,
    label: i18n.t('passive-cycle.options.expense-items.office'),
  },
  {
    value: CicloPassivoFieldsDtoExpenseItemEnumListEnum.AltriCostiDelPersonaleDiProduzione,
    label: i18n.t('passive-cycle.options.expense-items.other'),
  },
];

export const mappedOrderContractStatusEnumList: OrderOption[] = [
  {
    value: CicloPassivoFieldsDtoOrderContractStatusEnumListEnum.Attivo,
    label: i18n.t('passive-cycle.options.order-contract-status.active'),
  },
  {
    value: CicloPassivoFieldsDtoOrderContractStatusEnumListEnum.NonAttivo,
    label: i18n.t('passive-cycle.options.order-contract-status.not-active'),
  },
];

export const mappedOrderProcessingStatusEnumList: OrderOption[] = [
  {
    value: CicloPassivoFieldsDtoOrderProcessingStatusEnumListEnum.Avviato,
    label: i18n.t('passive-cycle.options.order-processing-status.started'),
  },
  {
    value: CicloPassivoFieldsDtoOrderProcessingStatusEnumListEnum.NonAvviato,
    label: i18n.t('passive-cycle.options.order-processing-status.not-started'),
  },
  {
    value: CicloPassivoFieldsDtoOrderProcessingStatusEnumListEnum.Concluso,
    label: i18n.t('passive-cycle.options.order-processing-status.finished'),
  },
];

export const mappedOrderTypeEnumList: OrderOption[] = [
  {
    value: CicloPassivoFieldsDtoOrderTypeEnumListEnum.Attivo,
    label: i18n.t('passive-cycle.options.order-type.active'),
  },
  {
    value: CicloPassivoFieldsDtoOrderTypeEnumListEnum.Passivo,
    label: i18n.t('passive-cycle.options.order-type.passive'),
  },
];
