import {
  DeleteMonthRequest,
  ExternalCostAllocationDto,
  ExternalCostDto,
  RevenueMonthDto,
  RevenuePlanDto,
  StaffExtendedDto,
} from '@api/client';
import LinksButton from '../../../../../../shared/design-system/components/links-button';
import {
  Checkbox,
  Col,
  Divider,
  Input,
  InputNumber,
  MenuProps,
  Row,
  Select,
  Tooltip,
  Dropdown,
  DatePicker,
  DatePickerProps,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Table, Button } from 'react-bootstrap';
import {
  filterOption,
  formatCurrency,
  getFormattedDateHeader,
  getFormattedDateString,
} from '../../../../../../shared/utils/common.utils';
import useGetOtherCostsTableData from '../../_hooks/other-costs/useGetOtherCostsTableData';
import { useAppDispatch, useAppSelector } from '../../../../../../core/store/hooks';
import { costiSelector } from '../../_redux/selectors';
import useCostsManagement from '../../_hooks/other-costs/useCostsManagement';
import React, { useState } from 'react';
import { CheckboxOptionType, CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckboxProps } from 'antd';
import useValidationCosts from '../../_hooks/other-costs/useValidationCosts';
import { useTranslation } from 'react-i18next';
import ModalAction from '../modal-action';
import locale from 'antd/es/date-picker/locale/en_US';
import MonthCheckList from '../months-check-list';
import NoData from '../../../../../../shared/design-system/components/no-data-box';
import AuthService from '../../../../../../core/auth/auth.service';
import dayjs from 'dayjs';
import { deleteMonths } from '../../_redux/actions';
import useValidationDescriptionCosts from '../../_hooks/other-costs/useValidationDescriptionCosts';
import useHandleAssociatedContracts from '../../_hooks/other-costs/useHandleAssociatedContracts';

interface Props {
  otherCosts: ExternalCostDto[] | undefined;
  years: (number | undefined)[];
  setYears: React.Dispatch<React.SetStateAction<(number | undefined)[]>>;
  isWorkInProgress: boolean;
  setTotalCostsValidation: React.Dispatch<React.SetStateAction<boolean>>;
  setDraftValidation: React.Dispatch<React.SetStateAction<boolean>>;
  fullAllocations: Partial<StaffExtendedDto>[] | null | undefined;
  totalsRevenuePlan: RevenuePlanDto[] | null | undefined;
  readOnly: boolean | undefined;
  revenueMonthToBeConsolidated: number | undefined | null;
  setRevenueMonthToBeConsolidated: React.Dispatch<React.SetStateAction<number | undefined>>;
  ricaviMeseDaConsolidare: RevenueMonthDto | undefined;
  idProject: number | undefined;
  otherCostsTableRef: React.RefObject<HTMLDivElement>;
  onOtherCostsScroll: (scrollLeft: number) => void;
  setCostDescriptionValidation: React.Dispatch<React.SetStateAction<boolean>>;
}

const OtherCostsTable: React.FC<Props> = ({
  otherCosts,
  years,
  setYears,
  isWorkInProgress,
  setTotalCostsValidation,
  setDraftValidation,
  fullAllocations,
  totalsRevenuePlan,
  readOnly,
  revenueMonthToBeConsolidated,
  setRevenueMonthToBeConsolidated,
  ricaviMeseDaConsolidare,
  idProject,
  otherCostsTableRef,
  onOtherCostsScroll,
  setCostDescriptionValidation,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const costList = useAppSelector(costiSelector);
  const [showModalAddMonths, setShowModalAddMonths] = useState(false);
  const [showModalDeleteMonths, setShowModalDeleteMonths] = useState(false);
  const [dateRange, setDateRange] = useState<number[]>([]);

  const { RangePicker } = DatePicker;
  const {
    otherCostsTableData,
    setOtherCostsTableData,
    otherCostsTableDataShown,
    totalOtherCosts,
    filterAllocationsByYear,
    currentYear,
    setTotalOtherCosts,
  } = useGetOtherCostsTableData(otherCosts, costList);

  const {
    handleChangeCost,
    handleChangeDescription,
    handleChangeAllocation,
    handleChangeTotalCosts,
    addCost,
    addMonth,
    addMonths,
    deleteCost,
    deleteMonth,
    disableAllocatedMonths,
    isNonDuplicableCostTypePresent,
  } = useCostsManagement(
    otherCostsTableData,
    setOtherCostsTableData,
    filterAllocationsByYear,
    currentYear,
    setTotalOtherCosts,
    setDraftValidation,
    costList,
    fullAllocations,
    dateRange,
    setYears
  );

  const { areTotalsCostsMatch, getTooltip } = useValidationCosts({
    totalOtherCosts,
    otherCostsTableData,
    setTotalCostsValidation,
  });

  useValidationDescriptionCosts({
    otherCostsTableData,
    setCostDescriptionValidation,
  });

  const { isAssociatedContractToCost, getTooltipAssociatedContracts, getValueAssociatedContracts } =
    useHandleAssociatedContracts(idProject);

  const [showModalSpalma, setShowModalSpalma] = useState(Array(otherCostsTableDataShown?.length).fill(false));

  const getCostiUtilizzati = (index: number) => {
    return otherCostsTableData?.[index].costAllocation?.filter((el) => el.editEnabled === false);
  };

  const getValue = (cost: ExternalCostDto) => {
    return cost.costType?.value
      ? {
          value: cost.costType?.value,
          label: t(`project-detail.cost-type-id-${costList?.find((el) => el.id == cost.costType?.id)?.id}`),
        }
      : null;
  };

  const monthOptionsObj =
    otherCostsTableData?.[0]?.costAllocation
      ?.filter((alloc) => alloc.editEnabled)
      ?.map((el) => ({
        label: el.mese,
        value: getFormattedDateString(el.mese, el.anno),
      })) ?? [];

  const getOptionsByYear = (year: number): CheckboxOptionType[] => {
    return monthOptionsObj.filter((el) => el.value.includes(year.toString()));
  };

  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(monthOptionsObj.map((el) => el.value));

  const onChange = (list: any[]) => {
    const lastToggledItem =
      list.find((item) => !checkedList.includes(item)) || checkedList.find((item) => !list.includes(item));
    if (typeof lastToggledItem === 'number') {
      const year = lastToggledItem;
      const isSelectingYear = !checkedList.includes(year);
      if (isSelectingYear) {
        const monthsOfYear = monthOptionsObj
          .filter((option) => option.value.includes(year.toString()))
          .map((option) => option.value);
        setCheckedList([...checkedList, ...monthsOfYear, year]);
      } else {
        const monthsOfYear = monthOptionsObj
          .filter((option) => option.value.includes(year.toString()))
          .map((option) => option.value);
        setCheckedList(checkedList.filter((item) => !monthsOfYear.includes(item.toString()) && item !== year));
      }
    } else {
      setCheckedList(list);
    }
  };

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    const monthOptions = monthOptionsObj.map((el) => el.value);
    setCheckedList(e.target.checked ? monthOptions : []);
    if (typeof e.target.value === 'number') {
      setCheckedList(monthOptions.map((el) => el.toString().includes(e.target.value)));
    }
  };
  const checkAll = monthOptionsObj.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < monthOptionsObj.length;

  const spalmaGiorni = (index: number) => {
    const totGiorni = totalOtherCosts?.[index] ?? 0;
    let newFullAllocations = otherCostsTableData?.map((el) => {
      if (el.costAllocation?.[index].id === otherCostsTableData?.[index].costAllocation?.[index]?.id) {
        return {
          ...el,
          costAllocation: el.costAllocation?.map((el) => {
            if (checkedList.includes(getFormattedDateString(el.mese, el.anno))) {
              const checkedListLenght = checkedList.filter((item, index) => {
                if (typeof item === 'string') {
                  return checkedList.indexOf(item) === index;
                }
                return false;
              }).length;
              return {
                ...el,
                actualCost: Number((totGiorni / checkedListLenght).toFixed(2)),
              };
            }
            if (el.editEnabled) {
              return {
                ...el,
                actualCost: 0,
              };
            }
            return el;
          }),
        };
      }
      return el;
    });
    setOtherCostsTableData(newFullAllocations);
    filterAllocationsByYear(currentYear, newFullAllocations);
  };

  const handleShowModalSpalma = (index: number, value: boolean) => {
    const newShowModal = [...showModalSpalma];
    newShowModal[index] = value;
    setShowModalSpalma(newShowModal);
  };

  const getCostTypeDescriptions = (id: number | undefined) => {
    return t(`project-detail.cost-type-id-${id}`);
  };

  const getFirstAllocationDate = () => {
    if (!otherCostsTableDataShown || otherCostsTableDataShown.length === 0) return null;

    const firstAllocation = otherCostsTableDataShown[0]?.costAllocation?.[0];
    if (!firstAllocation) return null;

    return getFormattedDateString(firstAllocation.mese, firstAllocation.anno);
  };

  const getLastAllocationDate = () => {
    if (!otherCostsTableDataShown || otherCostsTableDataShown.length === 0) return null;

    const lastAllocation = otherCostsTableDataShown[otherCostsTableDataShown.length - 1]?.costAllocation?.slice(-1)[0];
    if (!lastAllocation) return null;

    return getFormattedDateString(lastAllocation.mese, lastAllocation.anno);
  };

  const clearForecastCosts = (index: number) => {
    let newFullAllocations = otherCostsTableData?.map((el) => {
      if (el.costAllocation?.[0].id == otherCostsTableData?.[index].costAllocation?.[0].id) {
        return {
          ...el,
          costAllocation: el.costAllocation?.map((el) => {
            if (el.editEnabled) {
              return {
                ...el,
                actualCost: 0,
              };
            }
            return el;
          }),
        };
      }
      return el;
    });
    setOtherCostsTableData(newFullAllocations);
    filterAllocationsByYear(currentYear, newFullAllocations);
  };

  const handleMenuClick: (rowIndex: number) => MenuProps['onClick'] = (rowIndex) => (e) => {
    switch (e.key) {
      case '1':
        clearForecastCosts(rowIndex);
        break;
      case '2':
        deleteCost(rowIndex);
        break;
    }
  };

  const generateMenuItems = (rowIndex: number) => {
    return [
      {
        label: 'Clear Forecast Costs',
        key: '1',
        icon: (
          <i
            className={`icon-pulisci text-xl ${
              Number(totalOtherCosts?.[rowIndex]) <= 0 ? 'lightgrey' : 'text-blue-links'
            }`}
          />
        ),
        danger: false,
        disabled: Number(totalOtherCosts?.[rowIndex]) <= 0,
      },
      {
        label: 'Delete Cost',
        key: '2',
        icon: <i className="icon-delete text-xl" />,
        danger: true,
        disabled: !otherCostsTableData?.[rowIndex]?.costAllocation?.every((el) => el.id && el.id < 0),
      },
    ];
  };

  const menuProps = (rowIndex: number) => ({
    items: generateMenuItems(rowIndex),
    onClick: handleMenuClick(rowIndex),
  });

  const removeMonths = () => {
    let newOtherCosts = otherCostsTableData?.map((el) => {
      return {
        ...el,
        costAllocation: el.costAllocation?.filter(
          (el) => !checkedList.includes(getFormattedDateString(el.mese, el.anno))
        ),
      };
    });
    setOtherCostsTableData(newOtherCosts);
    filterAllocationsByYear(currentYear, newOtherCosts);

    if (idProject) {
      const monthsYears = getMonthsToDelete();
      const payload: DeleteMonthRequest = {
        deleteMonthsRequestDto: {
          idProject,
          monthsYears,
        },
      };
      dispatch(deleteMonths(payload));
    }
  };

  const getMonthsToDelete = () => {
    let stringArray: string[] = checkedList.filter((item): item is string => typeof item === 'string');

    // Eliminare i duplicati
    let uniqueArray: string[] = [];
    stringArray.forEach((item) => {
      if (!uniqueArray.includes(item)) {
        uniqueArray.push(item);
      }
    });

    // Mappare le stringhe in oggetti
    let monthsYears = uniqueArray.map((dateStr) => {
      let [month, year] = dateStr.split(' ');
      let monthNumber;

      // Convertire il nome del mese in numero
      switch (month) {
        case 'January':
          monthNumber = 1;
          break;
        case 'February':
          monthNumber = 2;
          break;
        case 'March':
          monthNumber = 3;
          break;
        case 'April':
          monthNumber = 4;
          break;
        case 'May':
          monthNumber = 5;
          break;
        case 'June':
          monthNumber = 6;
          break;
        case 'July':
          monthNumber = 7;
          break;
        case 'August':
          monthNumber = 8;
          break;
        case 'September':
          monthNumber = 9;
          break;
        case 'October':
          monthNumber = 10;
          break;
        case 'November':
          monthNumber = 11;
          break;
        case 'December':
          monthNumber = 12;
          break;
      }

      return { month: monthNumber, year: parseInt(year) };
    });
    return monthsYears;
  };

  const onDateChange: DatePickerProps['onChange'] = (dates, dateStr) => {
    if (!dates) setDateRange([]);
    const [meseInizio, annoInizio] = dateStr[0].split('/').map((str) => parseInt(str, 10));
    const [meseFine, annoFine] = dateStr[1].split('/').map((str) => parseInt(str, 10));
    setDateRange([meseInizio, annoInizio, meseFine, annoFine]);
  };

  const handleOtherCostsScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollLeft = target.scrollLeft;
    onOtherCostsScroll(scrollLeft);
  };

  const isDescriptionInError = (cost: ExternalCostDto) => {
    return cost.costType?.duplicable && !cost.description;
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-3" id="header-buttons">
        <div className="col-6">
          <LinksButton className="btn-sm btn btn-links-outline px-3" isDisabled={readOnly} onClick={addCost}>
            <div className="d-flex align-items-center">
              <i className="icon-aggiungi-collaboratore" />
              {t('project-detail.add-cost')}
            </div>
          </LinksButton>
          <LinksButton
            className="btn-sm btn btn-links-outline px-1"
            isDisabled={readOnly || otherCostsTableData?.length === 0}
            onClick={() => setShowModalAddMonths(true)}
          >
            <div className="d-flex align-items-center">
              <i className="icon-calendar-add" />
            </div>
          </LinksButton>
          <ModalAction
            title={t('project-detail.add-months')}
            actionLabel={t('common.add')}
            action={addMonths}
            setShow={(value) => setShowModalAddMonths(value)}
            show={showModalAddMonths}
          >
            <p>
              <b>{t('project-detail.add-new-months')}</b>
            </p>
            <div className="p-1">
              <RangePicker
                picker="month"
                format="MM/YYYY"
                minDate={dayjs(new Date())}
                locale={locale}
                getPopupContainer={(triggerMode) => {
                  return triggerMode.parentNode as HTMLElement;
                }}
                disabledDate={!AuthService.isSuperAdmin() ? disableAllocatedMonths : undefined}
                onChange={(dates: any, dateStrings: [string, string]) => onDateChange(dates, dateStrings)}
              />
            </div>
          </ModalAction>
          {monthOptionsObj.length > 0 && (
            <LinksButton
              className="btn-sm btn btn-outline-danger px-1"
              isDisabled={readOnly}
              onClick={() => setShowModalDeleteMonths(true)}
            >
              <div className="d-flex align-items-center">
                <i className="icon-calendar-delete" />
              </div>
            </LinksButton>
          )}
          <ModalAction
            title={t('project-detail.delete-months')}
            actionLabel={t('common.delete')}
            action={removeMonths}
            setShow={(value) => setShowModalDeleteMonths(value)}
            show={showModalDeleteMonths}
            size="lg"
          >
            <p>
              <b>{t('project-detail.delete-months')}</b>
            </p>
            <MonthCheckList
              monthOptionsObj={monthOptionsObj}
              checkedList={checkedList}
              onChange={onChange}
              onCheckAllChange={onCheckAllChange}
              years={years}
            />
          </ModalAction>
          <span className="mx-1 notification-header">
            {`${t('project-detail.revenues')} ${getFormattedDateHeader(
              ricaviMeseDaConsolidare?.month,
              ricaviMeseDaConsolidare?.year
            )} (€) :`}
          </span>
          <InputNumber
            style={{ width: '140px' }}
            decimalSeparator=","
            step={0.01}
            min={0}
            keyboard={false}
            disabled={ricaviMeseDaConsolidare?.editable === false}
            value={revenueMonthToBeConsolidated}
            onChange={(e) => setRevenueMonthToBeConsolidated(e as number)}
          />
        </div>
        <span className="ms-auto me-5 my-auto">
          <span className="mx-1 notification-header"> {t('project-detail.period')} :</span>
          <span className="mx-1">
            <b>{getFirstAllocationDate()}</b>
          </span>
          -
          <span className="mx-1">
            <b>{getLastAllocationDate()}</b>
          </span>
        </span>
        <Select defaultValue={'all'} onChange={(e) => filterAllocationsByYear(e as number | 'all')}>
          <Select.Option value="all">{t('project-detail.all')}</Select.Option>
          {years.map((year, index) => (
            <Select.Option key={'year-' + index} value={year}>
              {year}
            </Select.Option>
          ))}
        </Select>
      </div>
      {otherCostsTableData?.length === 0 ? (
        <NoData msg={t('project-detail.no-costs')} />
      ) : (
        <div
          className="d-flex table-responsive table-bordered container-table"
          ref={otherCostsTableRef}
          onScroll={handleOtherCostsScroll}
        >
          <Table className={'revenue-table sticky-table mt-0 mb-0'}>
            <thead>
              <tr>
                <th className="text-left">
                  <span>{t('project-detail.cost-type-amount')}</span>
                </th>
                <th className="text-left">
                  <span>{t('project-detail.cost-description')}</span>
                </th>
                {isWorkInProgress ? (
                  <th className="text-left" style={{ minWidth: '190px' }}>
                    <span>{t('project-detail.cost-remaining-amount-€')}</span>
                  </th>
                ) : (
                  <th className="text-left" style={{ minWidth: '190px' }}>
                    <span>{t('project-detail.cost-amount-€')}</span>
                  </th>
                )}
                <th className="text-left bg-disabled">
                  <span>{t('Amount')}</span>
                  <Tooltip title={t('Costs associated with contracts')} color="white">
                    <i className="fas fa-circle-info text-blue-links ps-2" />
                  </Tooltip>
                </th>
                {isWorkInProgress && (
                  <>
                    <th className="text-left bg-disabled">
                      <span>{t('project-detail.cost-used')}</span>
                    </th>
                    <th className="text-left bg-disabled">
                      <span>{t('project-detail.total-planned')}</span>
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {otherCostsTableDataShown?.map((cost: ExternalCostDto, index: number) => (
                <tr key={'ocost-td' + index}>
                  <td className="text-left p-0" style={{ minWidth: '290px', maxWidth: '290px' }}>
                    <Select
                      className="w-100"
                      placeholder={t('project-detail.cost-type-amount-placeholder')}
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOption}
                      value={getValue(cost)}
                      variant="borderless"
                      disabled={readOnly}
                      onChange={(e) => handleChangeCost(e, index)}
                    >
                      {costList?.map((el, index) => (
                        <Select.Option key={`${el.id}-${index}`} value={el.id}>
                          {getCostTypeDescriptions(el.id)}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  <td className="text-left p-0" style={{ minWidth: '290px', maxWidth: '290px' }}>
                    <Input
                      value={cost?.description}
                      placeholder={t('project-detail.cost-description-placeholder')}
                      disabled={readOnly || isAssociatedContractToCost(cost)}
                      onChange={(e) => handleChangeDescription(e.target.value, index)}
                      variant={!isDescriptionInError(cost) ? 'borderless' : undefined}
                      className="h-100"
                      status={isDescriptionInError(cost) ? 'error' : undefined}
                    />
                  </td>
                  <td className="text-left p-0">
                    <div className="d-flex justify-content-between h-100">
                      <Tooltip
                        title={areTotalsCostsMatch?.[index] ? '' : getTooltip(index)}
                        color="red"
                        key={`tooltip-total-${index}`}
                        className="w-100"
                      >
                        <InputNumber
                          value={Number(totalOtherCosts[index] ?? 0)}
                          disabled={readOnly}
                          onChange={(e) => handleChangeTotalCosts(e, index)}
                          decimalSeparator=","
                          variant="borderless"
                          className={areTotalsCostsMatch?.[index] ? '' : 'red-label'}
                          suffix={
                            <Tooltip title={`text`} key={`suffix-tooltip-${index}`}>
                              <ExclamationCircleOutlined
                                className="pe-3"
                                style={{ color: !areTotalsCostsMatch?.[index] ? 'red' : 'transparent' }}
                              />
                            </Tooltip>
                          }
                        />
                      </Tooltip>
                      <Tooltip
                        title={t('project-detail.spread-out-days')}
                        color="white"
                        key={`tooltip-spalma-${index}`}
                      >
                        <Button
                          className="btn-sm btn-icon border-none me-0"
                          disabled={readOnly}
                          onClick={() => handleShowModalSpalma(index, true)}
                        >
                          <i className="icon-calendar-spalma icon-primary align-middle"></i>
                        </Button>
                      </Tooltip>
                      <ModalAction
                        title={t('project-detail.spread-out-days')}
                        action={spalmaGiorni}
                        actionLabel={t('project-detail.divide')}
                        index={index}
                        key={`act-modal-${index}`}
                        setShow={(value) => handleShowModalSpalma(index, value)}
                        show={showModalSpalma[index]}
                        size="lg"
                      >
                        <div className="p-1">
                          <Checkbox
                            className="py-1"
                            key={`check-b-${index}`}
                            indeterminate={indeterminate}
                            onChange={onCheckAllChange}
                            checked={checkAll}
                          >
                            {t('project-detail.select-all')}
                          </Checkbox>
                          <Checkbox.Group
                            key={`check-b-group-${index}`}
                            value={checkedList}
                            style={{ width: '100%' }}
                            onChange={onChange}
                            className="d-flex flex-column"
                          >
                            {years.map((year) => {
                              const optionsByYear = getOptionsByYear(year!);
                              if (optionsByYear.length > 0) {
                                return (
                                  <React.Fragment key={`-year-${year}`}>
                                    <Row className="mb-1">
                                      <Col span={2}>
                                        <Checkbox
                                          key={`year-cb-${year}`}
                                          value={year}
                                          style={{ flexDirection: 'row-reverse' }}
                                        >
                                          <h6 className="text-left my-2 me-1">{year}</h6>
                                        </Checkbox>
                                      </Col>
                                      <Col span={22}>
                                        <Divider className="my-3 mx-3" key={`divider-${year}`} />
                                      </Col>
                                    </Row>
                                    <div
                                      style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(6, 1fr)',
                                        gridTemplateRows: 'repeat(2, 1fr)',
                                      }}
                                    >
                                      {optionsByYear.map((el) => {
                                        return (
                                          <Row
                                            key={`--year-${el.value}`}
                                            className="d-flex"
                                            style={{ minWidth: '120px' }}
                                          >
                                            <Col span={8}>
                                              <Checkbox value={el.value} className="mb-1" key={`cb-label-${year}`}>
                                                {getFormattedDateString(Number(el.label))}
                                              </Checkbox>
                                            </Col>
                                          </Row>
                                        );
                                      })}
                                    </div>
                                  </React.Fragment>
                                );
                              }
                              return null;
                            })}
                          </Checkbox.Group>
                        </div>
                      </ModalAction>
                      <Tooltip title={'Actions'} color="white" key={`actions-${index}`}>
                        <Dropdown menu={menuProps(index)} trigger={['click']}>
                          <i
                            className="fa-solid fa-ellipsis-vertical text-blue-links pt-2 pe-3 ps-1"
                            role="button"
                            onClick={(e) => e.preventDefault()}
                          ></i>
                        </Dropdown>
                      </Tooltip>
                    </div>
                  </td>
                  <td
                    className="text-left p-0 bg-disabled"
                    key={'Passive-cycle-amount-' + index}
                    style={{ minWidth: '110px' }}
                  >
                    <Tooltip
                      title={!isAssociatedContractToCost(cost) ? '' : getTooltipAssociatedContracts(cost)}
                      color="green"
                      key={`tooltip-total-${index}`}
                      className="w-100"
                    >
                      <Input
                        readOnly
                        className="bg-disabled"
                        value={getValueAssociatedContracts(cost)}
                        style={{ background: 'transparent', border: 'none' }}
                        suffix={
                          <Tooltip
                            title={!isAssociatedContractToCost(cost) ? '' : getTooltipAssociatedContracts(cost)}
                            color="green"
                            key={`suffix-tooltip-${index}`}
                          >
                            <ExclamationCircleOutlined
                              style={{ color: isAssociatedContractToCost(cost) ? 'green' : 'transparent' }}
                            />
                          </Tooltip>
                        }
                      />
                    </Tooltip>
                  </td>
                  {isWorkInProgress && (
                    <>
                      <td
                        className="text-left p-0 bg-disabled"
                        key={'Utilizzati-' + index}
                        style={{ minWidth: '100px' }}
                      >
                        <Input
                          readOnly
                          className="bg-disabled"
                          value={formatCurrency(
                            Number(getCostiUtilizzati(index)?.reduce((acc, el) => acc + (el.actualCost ?? 0), 0)) ?? 0
                          )}
                        />
                      </td>
                      <td className="text-left p-0 bg-disabled" key={'Tot/Pian-' + index} style={{ minWidth: '100px' }}>
                        <Input
                          readOnly
                          className="bg-disabled"
                          value={formatCurrency(
                            totalOtherCosts?.[index] +
                              Number(getCostiUtilizzati(index)?.reduce((acc, el) => acc + (el.actualCost ?? 0), 0))
                          )}
                        />
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          <Table className={'revenue-table mt-0 mb-0'}>
            <thead>
              <tr>
                {otherCostsTableDataShown?.[0]?.costAllocation?.map(
                  (allocation: ExternalCostAllocationDto, index: number) => (
                    <th
                      className={`text-left ${allocation?.editEnabled === false && 'bg-disabled'}`}
                      scope="col"
                      key={'cost-' + index}
                      style={{ minWidth: '100px', maxWidth: '100px' }}
                    >
                      <span>{getFormattedDateHeader(allocation?.mese, allocation?.anno)}</span>
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {otherCostsTableDataShown?.map((cost: ExternalCostDto, index: number) => (
                <tr key={'cost-' + index}>
                  {cost?.costAllocation?.map((allocation: ExternalCostAllocationDto, index: number) => (
                    <td
                      className={`text-center p-0 ${allocation?.editEnabled === false && 'bg-disabled'}`}
                      key={'cost-' + index}
                      style={{ minWidth: '80px', maxWidth: '80px' }}
                    >
                      <InputNumber
                        className="w-100"
                        key={'GU-per-month-' + index}
                        value={allocation.actualCost}
                        decimalSeparator=","
                        readOnly={readOnly}
                        disabled={allocation?.editEnabled === false}
                        min={0}
                        onChange={(e) => handleChangeAllocation(e, allocation?.id)}
                        variant="borderless"
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};

export default OtherCostsTable;
