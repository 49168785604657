/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ContractOrderExternalCostDto interface.
 */
export function instanceOfContractOrderExternalCostDto(value) {
    return true;
}
export function ContractOrderExternalCostDtoFromJSON(json) {
    return ContractOrderExternalCostDtoFromJSONTyped(json, false);
}
export function ContractOrderExternalCostDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'groupId': json['groupId'] == null ? undefined : json['groupId'],
        'projectId': json['projectId'] == null ? undefined : json['projectId'],
        'costTypeId': json['costTypeId'] == null ? undefined : json['costTypeId'],
        'amount': json['amount'] == null ? undefined : json['amount'],
        'externalCostDescription': json['externalCostDescription'] == null ? undefined : json['externalCostDescription'],
    };
}
export function ContractOrderExternalCostDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'groupId': value['groupId'],
        'projectId': value['projectId'],
        'costTypeId': value['costTypeId'],
        'amount': value['amount'],
        'externalCostDescription': value['externalCostDescription'],
    };
}
