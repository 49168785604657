import { useTranslation } from 'react-i18next';
import AppCard from '../../../../shared/design-system/components/app-card';
import { useState } from 'react';
import { Col, Row, Select } from 'antd';
import LinksButton from '../../../../shared/design-system/components/links-button';
import useGetSuppliers from './_hooks/useGetSuppliers';
import { filterOption } from '../../../../shared/utils/common.utils';
import { Table } from 'react-bootstrap';
import SuppliersCrudForm from './suppliers-crud-form';
import { FornitoreDto } from '@api/client';
import AppModal from '../../../../shared/design-system/components/app-modal';
import useDeleteSupplier from './_hooks/useDeleteSupplier';
import usePagination from '../../../../core/hooks/usePagination';
import AppPaginator from '../../../../shared/design-system/components/app-paginator';
import { StorageKeysEnum } from '../../../../enums/storageKeys.enum';

const SuppliersCrud: React.FC = () => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [supplierToEdit, setSupplierToEdit] = useState<FornitoreDto | undefined>(undefined);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const { supplierList, searchedSupplier, setSearchedSupplier, searchSupplier, allSuppliers, setAllSuppliers } =
    useGetSuppliers();
  const { deleteSupplierById, setIdToDelete } = useDeleteSupplier(setAllSuppliers);
  const {
    pageNumber: pageNumberUser,
    setPageNumber: setPageNumberUser,
    totalPages: totalPagesUser,
    visibleItems: visibleSuppliers,
  } = usePagination(supplierList || [], 50);

  return (
    <>
      <h4 className="text-gray-800 my-3">{t('Suppliers CRUD')}</h4>
      <AppCard title={editMode ? 'Update supplier' : 'Create supplier'} className="border-left-primary">
        <form
          onSubmit={(event) => {
            event.preventDefault();
            searchSupplier();
          }}
        >
          <Row>
            <Col lg={6} md={9} sm={18}>
              <div className="form-group">
                <label>{t('Supplier')}</label>
                <Select
                  className="w-100 me-3"
                  size="large"
                  placeholder={t('Search supplier')}
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  value={searchedSupplier}
                  onChange={(value) => setSearchedSupplier(value)}
                  allowClear
                >
                  {allSuppliers?.map((supplier) => (
                    <Select.Option key={supplier.id} value={supplier.id}>
                      {supplier.denominazione}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Col>
            <Col className="align-self-end mb-1 ms-3" lg={3} md={3} sm={3} xs={3}>
              <LinksButton type="submit" className="btn btn-links">
                {t('crud.crud-revenue-costs.button-label-search')}
              </LinksButton>
            </Col>
          </Row>
        </form>
        <div className="mt-3 pt-3" style={{ borderTop: '1px solid #dee2e6' }}>
          <SuppliersCrudForm
            editMode={editMode}
            setEditMode={setEditMode}
            supplier={supplierToEdit}
            setSupplier={setSupplierToEdit}
          />
        </div>
      </AppCard>

      <AppCard title="Suppliers list" className="mt-3">
        <Table bordered>
          <thead>
            <tr>
              <th>ID</th>
              <th>Denominazione</th>
              <th style={{ width: '100px' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {visibleSuppliers?.map((supplier) => (
              <tr key={supplier.id}>
                <td>{supplier.id}</td>
                <td>{supplier.denominazione}</td>
                <td>
                  <div className="d-flex">
                    <button
                      onClick={() => {
                        setEditMode(true);
                        setSupplierToEdit(supplier);
                      }}
                      className="btn-icon"
                    >
                      <i className={'icon-edit text-teal-links me-1'}></i>
                    </button>
                    <button
                      onClick={() => {
                        setShowConfirmDelete(true);
                        setIdToDelete(supplier.id);
                      }}
                      className="btn-icon"
                    >
                      <i className={'icon-delete'} style={{ color: 'red' }}></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {visibleSuppliers?.length > 0 ? (
          <AppPaginator
            keyPaginator={StorageKeysEnum.CURRENT_PAGE_SUPPLIERS}
            currentPage={pageNumberUser}
            totalPages={totalPagesUser}
            totalElements={supplierList.length || 0}
            pageSize={50}
            onPageChange={setPageNumberUser}
          />
        ) : (
          ''
        )}
      </AppCard>
      <AppModal
        setShow={setShowConfirmDelete}
        show={showConfirmDelete}
        title={t('common.attention')}
        cancelText={t('common.cancel')}
        confirmText={t('common.confirm')}
        onConfirm={() => deleteSupplierById()}
        onCancel={() => setShowConfirmDelete(false)}
      >
        <p>{'Are you sure you want to delete this supplier?'}</p>
      </AppModal>
    </>
  );
};

export default SuppliersCrud;
