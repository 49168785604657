import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../core/store/hooks';
import { allUsersSelector } from '../../pages/private/project-list/project-detail/_redux/selectors';
import { SearchUsersRequest } from '@api/client';
import { getUsers } from '../../pages/private/project-list/project-detail/_redux/actions';

const useGetAllUsers = () => {
  const dispatch = useAppDispatch();
  const allUsers = useAppSelector(allUsersSelector);

  useEffect(() => {
    if (!allUsers) {
      const request: SearchUsersRequest = {
        userSearchRequest: {},
      };
      dispatch(getUsers(request));
    }
  }, []);

  return { allUsers };
};

export default useGetAllUsers;
