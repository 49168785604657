/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { CicloPassivoFieldsDtoFromJSON, ContractOrderFromJSON, ContractOrderExternalCostDtoToJSON, ContractOrderOutputDtoFromJSON, ContractOrderOutputListDtoFromJSON, ContractOrderSearchDtoToJSON, CreateContractOrderDtoToJSON, FilterDtoToJSON, FornitoreDtoFromJSON, FornitoreDtoToJSON, ProjectCicloPassivoOutputListDtoFromJSON, } from '../models/index';
/**
 *
 */
export class ControllerPerLeFunzioniDedicateAlCicloPassivoApi extends runtime.BaseAPI {
    /**
     * Associa un contratto a dei costi esterni
     * @deprecated
     */
    associateExternalCostsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['contractOrderId'] == null) {
                throw new runtime.RequiredError('contractOrderId', 'Required parameter "contractOrderId" was null or undefined when calling associateExternalCosts().');
            }
            if (requestParameters['contractOrderExternalCostDto'] == null) {
                throw new runtime.RequiredError('contractOrderExternalCostDto', 'Required parameter "contractOrderExternalCostDto" was null or undefined when calling associateExternalCosts().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-passivo/associate/{contractOrderId}/external-costs`.replace(`{${"contractOrderId"}}`, encodeURIComponent(String(requestParameters['contractOrderId']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters['contractOrderExternalCostDto'].map(ContractOrderExternalCostDtoToJSON),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ContractOrderFromJSON(jsonValue));
        });
    }
    /**
     * Associa un contratto a dei costi esterni
     * @deprecated
     */
    associateExternalCosts(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.associateExternalCostsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Crea un nuovo ordine
     */
    createCicloPassivoRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['createContractOrderDto'] == null) {
                throw new runtime.RequiredError('createContractOrderDto', 'Required parameter "createContractOrderDto" was null or undefined when calling createCicloPassivo().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-passivo/create`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateContractOrderDtoToJSON(requestParameters['createContractOrderDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ContractOrderOutputDtoFromJSON(jsonValue));
        });
    }
    /**
     * Crea un nuovo ordine
     */
    createCicloPassivo(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createCicloPassivoRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Crea un nuovo fornitore
     */
    createFornitoreRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['fornitoreDto'] == null) {
                throw new runtime.RequiredError('fornitoreDto', 'Required parameter "fornitoreDto" was null or undefined when calling createFornitore().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-passivo/create-fornitore`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: FornitoreDtoToJSON(requestParameters['fornitoreDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => FornitoreDtoFromJSON(jsonValue));
        });
    }
    /**
     * Crea un nuovo fornitore
     */
    createFornitore(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createFornitoreRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Elimina un contratto e tutte le associazioni correlate
     */
    deleteContractAndAssociationsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['contractOrderId'] == null) {
                throw new runtime.RequiredError('contractOrderId', 'Required parameter "contractOrderId" was null or undefined when calling deleteContractAndAssociations().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-passivo/delete/{contractOrderId}`.replace(`{${"contractOrderId"}}`, encodeURIComponent(String(requestParameters['contractOrderId']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Elimina un contratto e tutte le associazioni correlate
     */
    deleteContractAndAssociations(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteContractAndAssociationsRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Elimina un fornitore
     */
    deleteFornitoreRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteFornitore().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-passivo/delete-fornitore/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Elimina un fornitore
     */
    deleteFornitore(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteFornitoreRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Ottieni tutti i fornitori
     */
    getAllFornitoriRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-passivo/fornitori`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FornitoreDtoFromJSON));
        });
    }
    /**
     * Ottieni tutti i fornitori
     */
    getAllFornitori(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAllFornitoriRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera un contratto
     */
    getContractOrderRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['contractId'] != null) {
                queryParameters['contractId'] = requestParameters['contractId'];
            }
            if (requestParameters['externalContractId'] != null) {
                queryParameters['externalContractId'] = requestParameters['externalContractId'];
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-passivo/contract`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ContractOrderOutputDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera un contratto
     */
    getContractOrder(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getContractOrderRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Ottieni un fornitore per ID
     */
    getFornitoreByIdRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getFornitoreById().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-passivo/fornitore/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => FornitoreDtoFromJSON(jsonValue));
        });
    }
    /**
     * Ottieni un fornitore per ID
     */
    getFornitoreById(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getFornitoreByIdRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera tutti gli othercost
     * OtherCost da associare
     */
    getProjectsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['idContract'] == null) {
                throw new runtime.RequiredError('idContract', 'Required parameter "idContract" was null or undefined when calling getProjects().');
            }
            if (requestParameters['filterDto'] == null) {
                throw new runtime.RequiredError('filterDto', 'Required parameter "filterDto" was null or undefined when calling getProjects().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-passivo/projects/{idContract}`.replace(`{${"idContract"}}`, encodeURIComponent(String(requestParameters['idContract']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: FilterDtoToJSON(requestParameters['filterDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProjectCicloPassivoOutputListDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera tutti gli othercost
     * OtherCost da associare
     */
    getProjects(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getProjectsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera tutti i possibili valori che il ciclo passivo può assumere
     * Possibili valori nelle select del ciclo passivo
     */
    getcicloPassivoFieldsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-passivo/fields`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CicloPassivoFieldsDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera tutti i possibili valori che il ciclo passivo può assumere
     * Possibili valori nelle select del ciclo passivo
     */
    getcicloPassivoFields(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getcicloPassivoFieldsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Modifica l\'amount dei costi esterni associati ad un contratto
     * @deprecated
     */
    removeExternalCostsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['contractOrderId'] == null) {
                throw new runtime.RequiredError('contractOrderId', 'Required parameter "contractOrderId" was null or undefined when calling removeExternalCosts().');
            }
            if (requestParameters['contractOrderExternalCostDto'] == null) {
                throw new runtime.RequiredError('contractOrderExternalCostDto', 'Required parameter "contractOrderExternalCostDto" was null or undefined when calling removeExternalCosts().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-passivo/update/{contractOrderId}/external-costs`.replace(`{${"contractOrderId"}}`, encodeURIComponent(String(requestParameters['contractOrderId']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters['contractOrderExternalCostDto'].map(ContractOrderExternalCostDtoToJSON),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ContractOrderOutputDtoFromJSON(jsonValue));
        });
    }
    /**
     * Modifica l\'amount dei costi esterni associati ad un contratto
     * @deprecated
     */
    removeExternalCosts(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.removeExternalCostsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Rimuovi dei costi esterni associati ad un contratto
     */
    removeExternalCosts1Raw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['contractOrderId'] == null) {
                throw new runtime.RequiredError('contractOrderId', 'Required parameter "contractOrderId" was null or undefined when calling removeExternalCosts1().');
            }
            if (requestParameters['contractOrderExternalCostDto'] == null) {
                throw new runtime.RequiredError('contractOrderExternalCostDto', 'Required parameter "contractOrderExternalCostDto" was null or undefined when calling removeExternalCosts1().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-passivo/remove/{contractOrderId}/external-costs`.replace(`{${"contractOrderId"}}`, encodeURIComponent(String(requestParameters['contractOrderId']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters['contractOrderExternalCostDto'].map(ContractOrderExternalCostDtoToJSON),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ContractOrderOutputDtoFromJSON(jsonValue));
        });
    }
    /**
     * Rimuovi dei costi esterni associati ad un contratto
     */
    removeExternalCosts1(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.removeExternalCosts1Raw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Ricerca contratti
     */
    searchContractOrdersRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['contractOrderSearchDto'] == null) {
                throw new runtime.RequiredError('contractOrderSearchDto', 'Required parameter "contractOrderSearchDto" was null or undefined when calling searchContractOrders().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-passivo/search`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ContractOrderSearchDtoToJSON(requestParameters['contractOrderSearchDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ContractOrderOutputListDtoFromJSON(jsonValue));
        });
    }
    /**
     * Ricerca contratti
     */
    searchContractOrders(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.searchContractOrdersRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Aggiorna un contratto e associa/modifica i costi esterni
     */
    updateContractAndAssociateExternalCostsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['createContractOrderDto'] == null) {
                throw new runtime.RequiredError('createContractOrderDto', 'Required parameter "createContractOrderDto" was null or undefined when calling updateContractAndAssociateExternalCosts().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-passivo/update`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters['createContractOrderDto'].map(CreateContractOrderDtoToJSON),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ContractOrderOutputDtoFromJSON(jsonValue));
        });
    }
    /**
     * Aggiorna un contratto e associa/modifica i costi esterni
     */
    updateContractAndAssociateExternalCosts(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateContractAndAssociateExternalCostsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Aggiorna un fornitore esistente
     */
    updateFornitoreRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['fornitoreDto'] == null) {
                throw new runtime.RequiredError('fornitoreDto', 'Required parameter "fornitoreDto" was null or undefined when calling updateFornitore().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-passivo/update-fornitore`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: FornitoreDtoToJSON(requestParameters['fornitoreDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => FornitoreDtoFromJSON(jsonValue));
        });
    }
    /**
     * Aggiorna un fornitore esistente
     */
    updateFornitore(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateFornitoreRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
