/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the DashboardProjectDto interface.
 */
export function instanceOfDashboardProjectDto(value) {
    return true;
}
export function DashboardProjectDtoFromJSON(json) {
    return DashboardProjectDtoFromJSONTyped(json, false);
}
export function DashboardProjectDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'projectId': json['projectId'] == null ? undefined : json['projectId'],
        'intranetId': json['intranetId'] == null ? undefined : json['intranetId'],
        'idStrutturaBusiness': json['idStrutturaBusiness'] == null ? undefined : json['idStrutturaBusiness'],
        'stato': json['stato'] == null ? undefined : json['stato'],
        'name': json['name'] == null ? undefined : json['name'],
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'totContratto': json['totContratto'] == null ? undefined : json['totContratto'],
        'pregresso': json['pregresso'] == null ? undefined : json['pregresso'],
        'actualRevenues': json['actualRevenues'] == null ? undefined : json['actualRevenues'],
        'backlog': json['backlog'] == null ? undefined : json['backlog'],
        'forecastRevenues': json['forecastRevenues'] == null ? undefined : json['forecastRevenues'],
        'afRevenues': json['afRevenues'] == null ? undefined : json['afRevenues'],
        'actualCosts': json['actualCosts'] == null ? undefined : json['actualCosts'],
        'actualHrCosts': json['actualHrCosts'] == null ? undefined : json['actualHrCosts'],
        'actualOtherCosts': json['actualOtherCosts'] == null ? undefined : json['actualOtherCosts'],
        'actualMargin': json['actualMargin'] == null ? undefined : json['actualMargin'],
        'actualMarginRate': json['actualMarginRate'] == null ? undefined : json['actualMarginRate'],
        'actualRate': json['actualRate'] == null ? undefined : json['actualRate'],
        'ricaviInizioAnno': json['ricaviInizioAnno'] == null ? undefined : json['ricaviInizioAnno'],
        'prjAFteAnno': json['prjAFteAnno'] == null ? undefined : json['prjAFteAnno'],
        'prjAFGU': json['prjAFGU'] == null ? undefined : json['prjAFGU'],
        'prjAGU': json['prjAGU'] == null ? undefined : json['prjAGU'],
        'prjAFRate': json['prjAFRate'] == null ? undefined : json['prjAFRate'],
        'prjFGU': json['prjFGU'] == null ? undefined : json['prjFGU'],
        'prjAFFteAnno': json['prjAFFteAnno'] == null ? undefined : json['prjAFFteAnno'],
        'prjAFMargin': json['prjAFMargin'] == null ? undefined : json['prjAFMargin'],
        'prjAFMarginPerc': json['prjAFMarginPerc'] == null ? undefined : json['prjAFMarginPerc'],
        'prjNPRicavi': json['prjNPRicavi'] == null ? undefined : json['prjNPRicavi'],
        'prjAFCosti': json['prjAFCosti'] == null ? undefined : json['prjAFCosti'],
    };
}
export function DashboardProjectDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'projectId': value['projectId'],
        'intranetId': value['intranetId'],
        'idStrutturaBusiness': value['idStrutturaBusiness'],
        'stato': value['stato'],
        'name': value['name'],
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString()),
        'totContratto': value['totContratto'],
        'pregresso': value['pregresso'],
        'actualRevenues': value['actualRevenues'],
        'backlog': value['backlog'],
        'forecastRevenues': value['forecastRevenues'],
        'afRevenues': value['afRevenues'],
        'actualCosts': value['actualCosts'],
        'actualHrCosts': value['actualHrCosts'],
        'actualOtherCosts': value['actualOtherCosts'],
        'actualMargin': value['actualMargin'],
        'actualMarginRate': value['actualMarginRate'],
        'actualRate': value['actualRate'],
        'ricaviInizioAnno': value['ricaviInizioAnno'],
        'prjAFteAnno': value['prjAFteAnno'],
        'prjAFGU': value['prjAFGU'],
        'prjAGU': value['prjAGU'],
        'prjAFRate': value['prjAFRate'],
        'prjFGU': value['prjFGU'],
        'prjAFFteAnno': value['prjAFFteAnno'],
        'prjAFMargin': value['prjAFMargin'],
        'prjAFMarginPerc': value['prjAFMarginPerc'],
        'prjNPRicavi': value['prjNPRicavi'],
        'prjAFCosti': value['prjAFCosti'],
    };
}
