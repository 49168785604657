import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Table } from 'react-bootstrap';
import { Tooltip } from 'antd';
import './styles.scss';
import { PathEnum } from '../../../../../../core/router/path.enum';

export interface TableProps {
  data: Array<any>;
  showModalDeleteOrder: (id: number) => void;
}
export interface DataTable {
  [key: string]: {
    id: number;
    label: string;
    value: string | number | boolean;
    visible: boolean;
    icon?: string;
    statusBadge?: string;
    className?: string;
    isButton?: boolean;
    tooltip?: string;
  };
}

const OrderListTable: React.FC<TableProps> = ({ data, showModalDeleteOrder }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToDetail = (id: number) => {
    if (!id) return;
    navigate(`/${PathEnum.PRIVATE}/${PathEnum.CICLO_PASSIVO}/${id}`);
  };

  if (data && data.length > 0) {
    const header = Object.keys(data[0]);
    return (
      <div className="table-responsive table-bordered">
        <Table className={'mt-0 mb-0'}>
          <thead className="bg-red">
            <tr>
              {header.map((el) => {
                if (data[0][el].visible) {
                  return (
                    <th className={`${data[0][el].className} align-middle`} key={el} scope="col">
                      <span>{data[0][el].label}</span>
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((e: DataTable, index: number) => (
              <tr key={'row-' + index}>
                {header.map((h) => {
                  if (data[0][h].visible) {
                    return (
                      <td className={`${data[0][h].className}`} key={`${h}-${index} `}>
                        {e[h].isButton && (
                          <div className="d-flex align-items-center justify-content-center">
                            <Tooltip placement="top" title="Delete">
                              <button
                                onClick={() => {
                                  showModalDeleteOrder(e?.id.value as number);
                                }}
                                className="btn-icon"
                                style={{ height: '10px' }}
                              >
                                <i className={'icon-delete text-red me-1'} />
                              </button>
                            </Tooltip>

                            <Tooltip placement="top" title={e[h]?.tooltip}>
                              <button
                                onClick={() => {
                                  goToDetail(e?.id.value as number);
                                }}
                                className="btn-icon"
                                style={{ height: '10px' }}
                              >
                                <i className={e[h].icon + ' text-blue-links me-1'} />
                              </button>
                            </Tooltip>
                          </div>
                        )}
                        <span className="d-inline-flex align-items-center">
                          {e[h].statusBadge && <div className={`order-status ${e[h].statusBadge}`}></div>}
                          <p className="my-auto">{e[h].value ?? ''}</p>
                        </span>
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '4rem' }}>
        {t('project-list.no-projects')}
      </div>
    );
  }
};

export default OrderListTable;
