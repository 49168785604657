import {
  CompetenzeProgettoDto,
  CostTypeDto,
  DetailContractExternalCostDto,
  ExternalCostDto,
  ProfiloProfessionaleDto,
  StaffExtendedDto,
  UserDto,
} from '@api/client';
import { createReducer } from '@reduxjs/toolkit';
import {
  getAssociatedContracts,
  getCompetenzeAnnoCorrente,
  getCompetenzeComplessive,
  getCosts,
  getRoles,
  getUsers,
  resetTeamAndCostsData,
  saveCostsData,
  saveRevenuesToBeConsolidated,
  saveTeamData,
} from './actions';

interface InitialState {
  ruoli: ProfiloProfessionaleDto[] | null;
  costi: CostTypeDto[] | null;
  allUsers: UserDto[] | null;
  teamData: Partial<StaffExtendedDto>[] | null | undefined;
  costsData: ExternalCostDto[] | null | undefined;
  revenuesToBeConsolidated: number | null | undefined;
  competenzeComplessive: CompetenzeProgettoDto | null | undefined;
  competenzeAnnoCorrente: CompetenzeProgettoDto | null | undefined;
  associatedContracts: DetailContractExternalCostDto[] | null | undefined;
}

const initialState: InitialState = {
  ruoli: null,
  costi: null,
  allUsers: null,
  teamData: null,
  costsData: null,
  revenuesToBeConsolidated: null,
  competenzeComplessive: null,
  competenzeAnnoCorrente: null,
  associatedContracts: null,
};

export const projectDetailReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getRoles.fulfilled, (state, action) => {
      state.ruoli = action.payload?.listaRuoli || [];
    })
    .addCase(getCosts.fulfilled, (state, action) => {
      state.costi = action.payload?.otherCostsList || [];
    })
    .addCase(getUsers.fulfilled, (state, action) => {
      state.allUsers = action.payload?.utenti || [];
    })
    .addCase(saveTeamData, (state, action) => {
      state.teamData = action.payload || null;
    })
    .addCase(saveCostsData, (state, action) => {
      state.costsData = action.payload || null;
    })
    .addCase(resetTeamAndCostsData, (state, action) => {
      state.teamData = null;
      state.costsData = null;
      state.competenzeComplessive = null;
      state.competenzeAnnoCorrente = null;
      state.associatedContracts = null;
    })
    .addCase(saveRevenuesToBeConsolidated, (state, action) => {
      state.revenuesToBeConsolidated = action.payload;
    })
    .addCase(getCompetenzeComplessive.fulfilled, (state, action) => {
      state.competenzeComplessive = action.payload;
    })
    .addCase(getCompetenzeAnnoCorrente.fulfilled, (state, action) => {
      state.competenzeAnnoCorrente = action.payload;
    })
    .addCase(getAssociatedContracts.fulfilled, (state, action) => {
      state.associatedContracts = action.payload;
    });
});
