/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContractInfoDTOFromJSON, ContractInfoDTOToJSON, } from './ContractInfoDTO';
/**
 * Check if a given object implements the DetailContractExternalCostDto interface.
 */
export function instanceOfDetailContractExternalCostDto(value) {
    return true;
}
export function DetailContractExternalCostDtoFromJSON(json) {
    return DetailContractExternalCostDtoFromJSONTyped(json, false);
}
export function DetailContractExternalCostDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'groupId': json['groupId'] == null ? undefined : json['groupId'],
        'quoteId': json['quoteId'] == null ? undefined : json['quoteId'],
        'costTypeId': json['costTypeId'] == null ? undefined : json['costTypeId'],
        'contracts': json['contracts'] == null ? undefined : (json['contracts'].map(ContractInfoDTOFromJSON)),
    };
}
export function DetailContractExternalCostDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'groupId': value['groupId'],
        'quoteId': value['quoteId'],
        'costTypeId': value['costTypeId'],
        'contracts': value['contracts'] == null ? undefined : (value['contracts'].map(ContractInfoDTOToJSON)),
    };
}
