import { ExternalCostDto } from '@api/client';
import { formatCurrency } from '../../../../../../shared/utils/common.utils';
import { useAppDispatch, useAppSelector } from '../../../../../../core/store/hooks';
import { associatedContractsSelector } from '../../_redux/selectors';
import { useEffect } from 'react';
import { getAssociatedContracts } from '../../_redux/actions';

const useHandleAssociatedContracts = (idProject: number | undefined) => {
  const dispatch = useAppDispatch();
  const associatedContracts = useAppSelector(associatedContractsSelector);

  useEffect(() => {
    if (!associatedContracts && idProject) {
      dispatch(getAssociatedContracts(idProject));
    }
  }, []);

  const isAssociatedContractToCost = (cost: ExternalCostDto) => {
    return !!associatedContracts?.find(
      (pcCost) => pcCost.costTypeId === cost.costType?.id && pcCost.groupId === cost.groupId
    );
  };

  const getValueAssociatedContracts = (cost: ExternalCostDto) => {
    if (!isAssociatedContractToCost(cost)) return '-';
    return formatCurrency(
      associatedContracts
        ?.find((pcCost) => pcCost.costTypeId === cost.costType?.id && pcCost.groupId === cost.groupId)
        ?.contracts?.reduce((acc, el) => acc + (el.amount ?? 0), 0) ?? 0
    );
  };

  const getTooltipAssociatedContracts = (cost: ExternalCostDto) => {
    if (!isAssociatedContractToCost(cost)) return '-';
    return associatedContracts
      ?.find((pcCost) => pcCost.costTypeId === cost.costType?.id && pcCost.groupId === cost.groupId)
      ?.contracts?.map((el) => `Contract ${el.externalContractId}: ${formatCurrency(el.amount ?? 0)}€`)
      .join(', ');
  };

  return { isAssociatedContractToCost, getTooltipAssociatedContracts, getValueAssociatedContracts };
};

export default useHandleAssociatedContracts;
