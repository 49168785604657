import { ProjectCicloPassivoOutputDto } from '@api/client';
import { Input, InputNumber } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormBootstrap from 'react-bootstrap/Form';
import './styles.scss';
import { ExternalCostWithAmount, ProjectCicloPassivoOutputWithAmount } from '../../_models/AssociableProjects';

interface Props {
  project: ProjectCicloPassivoOutputWithAmount;
  handleChangeAssociated: (
    e: ChangeEvent<HTMLInputElement>,
    cost: ExternalCostWithAmount,
    project: ProjectCicloPassivoOutputDto
  ) => void;
  handleChangeAmount: (
    e: number | undefined,
    cost: ExternalCostWithAmount,
    project: ProjectCicloPassivoOutputDto
  ) => void;
  handleChangeDescription: (
    e: string | undefined,
    cost: ExternalCostWithAmount,
    project: ProjectCicloPassivoOutputDto
  ) => void;
}

const PassiveCycleProjectCard: React.FC<Props> = ({
  project,
  handleChangeAssociated,
  handleChangeAmount,
  handleChangeDescription,
}) => {
  const { t } = useTranslation();
  const [showExternalCosts, setShowExternalCosts] = useState<boolean>(false);
  const [originalProject, setOriginalProject] = useState<ProjectCicloPassivoOutputDto | undefined>(undefined);

  useEffect(() => {
    if (!originalProject) {
      setOriginalProject(project);
    }
  }, [originalProject, project]);

  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="dflex flex-column">
          <div className="d-flex justify-content-between" style={{ maxHeight: '25px' }}>
            <h6 className="fw-bold align-content-center">{`${project.projectId} (${project.intranetId}) - ${project.projectName}`}</h6>

            <button
              className="download btn btn-sm btn-links-no-border d-flex align-items-top p-0"
              onClick={() => setShowExternalCosts(!showExternalCosts)}
            >
              <p className="text-blue-links fw-bold">
                {showExternalCosts ? t('Hide external costs') : t('Show external costs >')}
              </p>
            </button>
          </div>
          {showExternalCosts && (
            <div className="mt-4">
              <div className="row">
                {project.otherCostList?.otherCosts?.map((cost, index) => (
                  <div key={index} className="col-6 d-flex justify-content-between align-items-center mb-3">
                    <div className="col-9 d-flex align-items-center">
                      <FormBootstrap.Check
                        type={'checkbox'}
                        checked={cost.isAssociated}
                        disabled={originalProject?.otherCostList?.otherCosts?.[index].isAssociated}
                        onChange={(e) => handleChangeAssociated(e, cost, project)}
                      />
                      <label className="w-100 ms-2 me-4">
                        {t(`project-detail.cost-type-id-${cost.costType?.id}`)}
                        <br />
                        <Input
                          type="text"
                          className="w-100"
                          placeholder={t('Insert description')}
                          value={cost.description}
                          onChange={(e) => handleChangeDescription(e.target.value, cost, project)}
                          size="middle"
                          disabled={
                            originalProject?.otherCostList?.otherCosts?.[index].isAssociated || !cost.isAssociated
                          }
                        />
                      </label>
                    </div>
                    <div className=" col-3 input-num-wrapper d-flex flex-column">
                      <label className="w-100">{t(`Amount`)}</label>
                      <InputNumber
                        type="number"
                        className="w-100"
                        placeholder={t('Insert amount')}
                        min={0}
                        step="0.01"
                        value={cost.amount}
                        onChange={(e) => handleChangeAmount(e as number | undefined, cost, project)}
                        size="middle"
                        disabled={
                          originalProject?.otherCostList?.otherCosts?.[index].isAssociated || !cost.isAssociated
                        }
                        suffix={'€'}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default PassiveCycleProjectCard;
