import {
  ContractOrderOutputDto,
  CreateContractOrderDto,
  UpdateContractAndAssociateExternalCostsRequest,
  UserDto,
} from '@api/client';
import { useTranslation } from 'react-i18next';
import useGetPassiveCycleOptions from '../../../../../_hooks/useGetPassiveCycleOptions';
import { Col, Row } from 'react-bootstrap';
import { filterOption } from '../../../../../../../../shared/utils/common.utils';
import { DatePicker, Form, Input, Select } from 'antd';
import { useAppDispatch } from '../../../../../../../../core/store/hooks';
import { searchOrders, updateOrder } from '../../../../../_redux/actions';
import { Dispatch, SetStateAction } from 'react';
import { Formik, FormikState } from 'formik';
import dayjs from 'dayjs';
import FormBootstrap from 'react-bootstrap/Form';
import LinksButton from '../../../../../../../../shared/design-system/components/links-button';

interface Props {
  order: ContractOrderOutputDto;
  allUsers: UserDto[] | null;
  setEditMode: Dispatch<SetStateAction<boolean>>;
}

const OrderDataEdit: React.FC<Props> = ({ order, allUsers, setEditMode }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { mappedPassiveCycleOptions } = useGetPassiveCycleOptions();

  const handleSubmit = (values: any) => {
    const payload: UpdateContractAndAssociateExternalCostsRequest = {
      createContractOrderDto: [
        {
          contractOrderId: order.id,
          idExternalContract: values.idExternalContract,
          contractStatus: values.contractStatus,
          processingStatus: values.processingStatus,
          expenseItemList: values.expenseItemList?.flatMap((el: any) => el.value),
          fornitore: values.fornitore,
          contractValue: values.contractValue,
          resaleProjectCode: values.resaleProjectCode,
          resaleDetachmentRate: values.resaleDetachmentRate,
          linkDocumentation: values.linkDocumentation,
          category: values.category,
          legalEntity: values.legalEntity,
          object: values.object,
          automaticRenewal: values.automaticRenewal,
          startDate: values.startDate,
          endDate: values.endDate,
          signatureDate: values.signatureDate,
          cancellationWithin: values.cancellationWithin,
          withdrawalWithinLiteral: values.withdrawalWithinLiteral,
          cancellationProcedure: values.cancellationProcedure,
          cancelAtAnyTime: values.cancelAtAnyTime,
          idSede: values.idSede,
          fkUtente: values.fkUtente,
          linkTicket: values.linkTicket,
          note: values.note,
        },
      ],
    };
    dispatch(updateOrder(payload))
      .unwrap()
      .then((res) => {
        if (res) {
          setEditMode(false);
          dispatch(searchOrders({ contractOrderSearchDto: {} }));
        }
      });
  };

  const initialValues: Partial<FormikState<CreateContractOrderDto>> = {
    values: {
      idExternalContract: order.idExternalContract,
      contractStatus: order.contractStatus?.value,
      processingStatus: order.processingStatus?.value,
      expenseItemList: order.expenseItems?.filter((el) => el.expenseItemId).map((el) => el.expenseItemId as string),
      fornitore: order.idFornitore?.denominazione,
      contractValue: order.contractValue,
      resaleProjectCode: order.resaleProjectCode,
      linkDocumentation: order.linkDocumentation,
      category: order.category?.id,
      legalEntity: order.legalEntity?.id,
      object: order.object,
      automaticRenewal: order.automaticRenewal,
      startDate: order.startDate,
      endDate: order.endDate,
      signatureDate: order.signatureDate,
      cancellationWithin: order.cancellationWithin,
      withdrawalWithinLiteral: order.withdrawalWithinLiteral,
      cancellationProcedure: order.cancellationProcedure,
      cancelAtAnyTime: order.cancelAtAnyTime,
      idSede: order.idSede?.id,
      fkUtente: order.fkUtente,
      linkTicket: order.linkTicket,
      note: order.note,
    },
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues?.values as CreateContractOrderDto}>
      {({ handleSubmit: _handleSubmit, handleChange, setFieldValue, values, touched, errors }) => {
        const handleChangeCustom = (input: string) => (e: any) => {
          setFieldValue(input, e);
        };

        const onChangeDate = (input: string) => (date: dayjs.Dayjs) => {
          setFieldValue(input, date ? new Date(date.format('YYYY-MM-DD')) : null);
        };

        const handleChangeCheckbox = (input: string) => (e: any) => {
          setFieldValue(input, e.target.checked);
        };

        return (
          <Form onFinish={_handleSubmit}>
            <Row>
              <Col lg={3} md={12} sm={12} xs={12}>
                <div className="form-group">
                  <label>{t('ID *')}</label>
                  <Form.Item
                    name={t('ID')}
                    className="mb-3"
                    initialValue={values.idExternalContract}
                    rules={[{ required: true }]}
                  >
                    <Input
                      type="text"
                      name="idExternalContract"
                      placeholder={t('Insert ID')}
                      value={values.idExternalContract}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col lg={3} md={6} sm={12} xs={12}>
                <div className="form-group">
                  <label>{t('Contract status *')}</label>
                  <Form.Item
                    name={t('Contract status')}
                    className="mb-3"
                    initialValue={values.contractStatus}
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      className="w-100"
                      optionFilterProp="children"
                      filterOption={filterOption}
                      allowClear
                      placeholder={t('Select Contract status')}
                      onChange={handleChangeCustom('contractStatus')}
                      value={values.contractStatus}
                      getPopupContainer={(triggerMode) => {
                        return triggerMode.parentNode as HTMLElement;
                      }}
                    >
                      {mappedPassiveCycleOptions?.mappedOrderContractStatusEnumList?.map((el) => (
                        <Select.Option key={`status-${el.value}`} value={el.value}>
                          {el.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={3} md={6} sm={12} xs={12}>
                <div className="form-group">
                  <label>{t('Processing status *')}</label>
                  <Form.Item
                    name={t('Processing status')}
                    className="mb-3"
                    initialValue={values.processingStatus}
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      className="w-100"
                      optionFilterProp="children"
                      filterOption={filterOption}
                      allowClear
                      placeholder={t('Select Processing status')}
                      onChange={handleChangeCustom('processingStatus')}
                      value={values.processingStatus}
                      getPopupContainer={(triggerMode) => {
                        return triggerMode.parentNode as HTMLElement;
                      }}
                    >
                      {mappedPassiveCycleOptions?.mappedOrderProcessingStatusEnumList?.map((el) => (
                        <Select.Option key={`status-${el.value}`} value={el.value}>
                          {el.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={3} md={6} sm={12} xs={12}>
                <div className="form-group">
                  <label>{t('Supplier *')}</label>
                  <Form.Item
                    name={t('Supplier')}
                    className="mb-3"
                    initialValue={values.fornitore}
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      className="w-100"
                      optionFilterProp="children"
                      filterOption={filterOption}
                      allowClear
                      placeholder={t('Select Supplier')}
                      onChange={handleChangeCustom('fornitore')}
                      value={values.fornitore}
                      getPopupContainer={(triggerMode) => {
                        return triggerMode.parentNode as HTMLElement;
                      }}
                    >
                      {mappedPassiveCycleOptions?.fornitoriList?.map((el) => (
                        <Select.Option key={`fornitore-${el.id}`} value={el.denominazione}>
                          {el.denominazione}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={3} md={12} sm={12} xs={12}>
                <div className="form-group">
                  <label>{t('Supply amount *')}</label>
                  <Form.Item
                    name={t('Supply amount')}
                    className="mb-3"
                    initialValue={values.contractValue}
                    rules={[{ required: true }]}
                  >
                    <Input
                      type="number"
                      name="contractValue"
                      placeholder={t('Insert supply amount')}
                      value={values.contractValue}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col lg={3} md={6} sm={12} xs={12}>
                <div className="form-group">
                  <label>{t('Order Category *')}</label>
                  <Form.Item
                    name={t('Order Category')}
                    className="mb-3"
                    initialValue={values.category}
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      className="w-100"
                      optionFilterProp="children"
                      filterOption={filterOption}
                      allowClear
                      placeholder={t('Select order category')}
                      onChange={handleChangeCustom('category')}
                      value={values.category}
                      getPopupContainer={(triggerMode) => {
                        return triggerMode.parentNode as HTMLElement;
                      }}
                    >
                      {mappedPassiveCycleOptions?.orderCategoryList?.map((el) => (
                        <Select.Option key={`category-${el.id}`} value={el.id}>
                          {el.value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={3} md={6} sm={12} xs={12}>
                <div className="form-group">
                  <label>{t('Legal Entity *')}</label>
                  <Form.Item
                    name={t('Legal Entity')}
                    className="mb-3"
                    initialValue={values.legalEntity}
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      className="w-100"
                      optionFilterProp="children"
                      filterOption={filterOption}
                      allowClear
                      placeholder={t('Select legal entity')}
                      onChange={handleChangeCustom('legalEntity')}
                      value={values.legalEntity}
                      getPopupContainer={(triggerMode) => {
                        return triggerMode.parentNode as HTMLElement;
                      }}
                    >
                      {mappedPassiveCycleOptions?.aziendaList?.map((el) => (
                        <Select.Option key={`legalEntity-${el.id}`} value={el.id}>
                          {el.nome}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={3} md={6} sm={12} xs={12}>
                <div className="form-group">
                  <label>{t('Order supervisor *')}</label>
                  <Form.Item
                    name={t('Order supervisor')}
                    className="mb-3"
                    initialValue={values.fkUtente}
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      className="w-100"
                      optionFilterProp="children"
                      filterOption={filterOption}
                      allowClear
                      placeholder={t('Select order supervisor')}
                      onChange={handleChangeCustom('fkUtente')}
                      value={values.fkUtente}
                      getPopupContainer={(triggerMode) => {
                        return triggerMode.parentNode as HTMLElement;
                      }}
                    >
                      {allUsers?.map((el) => (
                        <Select.Option key={`fkUtente-${el.id}`} value={el.id}>
                          {el.nominativo}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
            </Row>
            {/* <p className="text-gray-600 fw-bold">Deadlines</p> */}
            <Row>
              <Col lg={3} md={6} sm={6} xs={6}>
                <div className="form-group">
                  <label>{t('Start date *')}</label>
                  <Form.Item
                    name={t('Start date')}
                    className="mb-3"
                    initialValue={values.startDate ? dayjs(values.startDate) : null}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      className="w-100"
                      format="DD/MM/YYYY"
                      allowClear
                      placeholder={t('Insert start date *')}
                      value={values.startDate ? dayjs(values.startDate) : null}
                      onChange={onChangeDate('startDate')}
                      suffixIcon={<i className="far fa-calendar text-blue-links" />}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col lg={3} md={6} sm={6} xs={6}>
                <div className="form-group">
                  <label>{t('End date *')}</label>
                  <Form.Item
                    name={t('End date')}
                    className="mb-3"
                    initialValue={values.endDate ? dayjs(values.endDate) : null}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      className="w-100"
                      format="DD/MM/YYYY"
                      allowClear
                      placeholder={t('Insert end date')}
                      value={values.endDate ? dayjs(values.endDate) : null}
                      onChange={onChangeDate('endDate')}
                      suffixIcon={<i className="far fa-calendar text-blue-links" />}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col lg={3} md={6} sm={6} xs={6}>
                <div className="form-group">
                  <label>{t('Signature date *')}</label>
                  <Form.Item
                    name={t('Signature date')}
                    className="mb-3"
                    initialValue={values.signatureDate ? dayjs(values.signatureDate) : null}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      className="w-100"
                      format="DD/MM/YYYY"
                      allowClear
                      placeholder={t('Insert signature date')}
                      value={values.signatureDate ? dayjs(values.signatureDate) : null}
                      onChange={onChangeDate('signatureDate')}
                      suffixIcon={<i className="far fa-calendar text-blue-links" />}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col lg={3} md={6} sm={6} xs={6}>
                <div className="form-group">
                  <label>{t('Cancellation Within')}</label>
                  <Form.Item
                    name={t('Cancellation Within')}
                    className="mb-3"
                    initialValue={values.cancellationWithin ? dayjs(values.cancellationWithin) : null}
                  >
                    <DatePicker
                      className="w-100"
                      format="DD/MM/YYYY"
                      allowClear
                      placeholder={t('Insert date')}
                      value={values.cancellationWithin ? dayjs(values.cancellationWithin) : null}
                      onChange={onChangeDate('cancellationWithin')}
                      suffixIcon={<i className="far fa-calendar text-blue-links" />}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={3} md={6} sm={12} xs={12}>
                <div className="form-group">
                  <label>{t('Expenses')}</label>
                  <Form.Item name={t('Expenses')} className="mb-3" initialValue={values.expenseItemList}>
                    <Select
                      showSearch
                      className="w-100"
                      optionFilterProp="children"
                      filterOption={filterOption}
                      allowClear
                      mode="multiple"
                      placeholder={t('Select Expenses')}
                      onChange={handleChangeCustom('expenseItemList')}
                      value={values.expenseItemList}
                      getPopupContainer={(triggerMode) => {
                        return triggerMode.parentNode as HTMLElement;
                      }}
                    >
                      {mappedPassiveCycleOptions?.mappedExpenseItemEnumList?.map((el) => (
                        <Select.Option key={`status-${el.value}`} value={el.value}>
                          {el.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={3} md={12} sm={12} xs={12}>
                <div className="form-group">
                  <label>{t('Resale project code')}</label>
                  <Form.Item name={t('Resale project code')} className="mb-3" initialValue={values.resaleProjectCode}>
                    <Input
                      type="text"
                      name="resaleProjectCode"
                      placeholder={t('Insert resale project code')}
                      value={values.resaleProjectCode}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
              </Col>
              {/* <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Resale detachment rate')}</label>
                        <Form.Item
                          name={t('Resale detachment rate')}
                          className="mb-3"
                          initialValue={values.resaleDetachmentRate}
                        >
                          <Input
                            type="number"
                            name="resaleDetachmentRate"
                            placeholder={t('Insert resale detachment rate')}
                            value={values.resaleDetachmentRate}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                    </Col> */}
              <Col lg={3} md={12} sm={12} xs={12}>
                <div className="form-group">
                  <label>{t('Link documentation')}</label>
                  <Form.Item name={t('Link documentation')} className="mb-3" initialValue={values.linkDocumentation}>
                    <Input
                      type="text"
                      name="linkDocumentation"
                      placeholder={t('Insert link documentation')}
                      value={values.linkDocumentation}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col lg={3} md={12} sm={12} xs={12}>
                <div className="form-group">
                  <label>{t('Link ticket')}</label>
                  <Form.Item name={t('Link ticket')} className="mb-3" initialValue={values.linkTicket}>
                    <Input
                      type="text"
                      name="linkTicket"
                      placeholder={t('Insert link ticket')}
                      value={values.linkTicket}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={3} md={6} sm={6} xs={6}>
                <div className="form-group">
                  <label>{t('Withdrawal within')}</label>
                  <Form.Item
                    name={t('Withdrawal within')}
                    className="mb-3"
                    initialValue={values.withdrawalWithinLiteral}
                  >
                    <Input
                      type="text"
                      name="withdrawalWithinLiteral"
                      placeholder={t('Insert withdrawal within')}
                      value={values.withdrawalWithinLiteral}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col lg={3} md={12} sm={12} xs={12}>
                <div className="form-group">
                  <label>{t('Cancellation procedure')}</label>
                  <Form.Item
                    name={t('Cancellation procedure')}
                    className="mb-3"
                    initialValue={values.cancellationProcedure}
                  >
                    <Input
                      type="text"
                      name="cancellationProcedure"
                      placeholder={t('Insert cancellation procedure')}
                      value={values.cancellationProcedure}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col lg={3} md={6} sm={12} xs={12}>
                <div className="form-group">
                  <label>{t('Location')}</label>
                  <Form.Item name={t('Location')} className="mb-3" initialValue={values.idSede}>
                    <Select
                      showSearch
                      className="w-100"
                      optionFilterProp="children"
                      filterOption={filterOption}
                      allowClear
                      placeholder={t('Select location')}
                      onChange={handleChangeCustom('idSede')}
                      value={values.idSede}
                      getPopupContainer={(triggerMode) => {
                        return triggerMode.parentNode as HTMLElement;
                      }}
                    >
                      {mappedPassiveCycleOptions?.sedeList?.map((el) => (
                        <Select.Option key={`idSede-${el.id}`} value={el.id}>
                          {el.denominazione}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={3} md={12} sm={12} xs={12}>
                <div className="form-group mt-2">
                  <FormBootstrap.Check
                    type={'checkbox'}
                    onChange={handleChangeCheckbox('automaticRenewal')}
                    checked={values.automaticRenewal}
                    label={`Automatic renewal`}
                  />
                  <FormBootstrap.Check
                    type={'checkbox'}
                    onChange={handleChangeCheckbox('cancelAtAnyTime')}
                    checked={values.cancelAtAnyTime}
                    label={`Cancel at any time`}
                  />
                </div>
              </Col>
              {/* <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group" style={{ marginTop: '1.75rem' }}>
                        <FormBootstrap.Check
                          type={'checkbox'}
                          onChange={handleChangeCheckbox('cancelAtAnyTime')}
                          checked={values.cancelAtAnyTime}
                          label={`Cancel at any time`}
                        />
                      </div>
                    </Col> */}
            </Row>
            {/* <p className="text-gray-600 fw-bold">Object and Notes</p> */}
            <Row className="align-items-center">
              <Col lg={6} md={12} sm={12} xs={12}>
                <div className="form-group">
                  <label>{t('Object *')}</label>
                  <Form.Item name={t('Object')} initialValue={values.object} rules={[{ required: true }]}>
                    <Input.TextArea
                      name="object"
                      placeholder={t('Insert object')}
                      value={values.object}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col lg={6} md={12} sm={12} xs={12}>
                <div className="form-group">
                  <label>{t('Notes')}</label>
                  <Form.Item name={t('Notes')} initialValue={values.note}>
                    <Input.TextArea
                      name="note"
                      placeholder={t('Insert notes')}
                      value={values.note}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <div className="d-flex justify-content-end">
              <LinksButton className="btn btn-links-outline" onClick={() => setEditMode(false)}>
                Cancel
              </LinksButton>
              <LinksButton type="submit" className="btn-primary btn-links">
                Save
              </LinksButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default OrderDataEdit;
