import {
  CicloPassivoFieldsDto,
  ContractOrderOutputDto,
  ContractOrderSearchDto,
  FornitoreDto,
  ProjectCicloPassivoOutputDto,
} from '@api/client';
import {
  createOrder,
  getOtherAssociatibleCosts,
  getPassiveCycleOptions,
  getSupplierById,
  getSuppliers,
  saveFiltersOrdersUsed,
  searchOrders,
} from './actions';
import { createReducer } from '@reduxjs/toolkit';
interface InitialState {
  passiveCycleOptions: CicloPassivoFieldsDto | undefined;
  orders: ContractOrderOutputDto[];
  filtersOrdersUsed: ContractOrderSearchDto | undefined;
  associableOtherCostsProjects: ProjectCicloPassivoOutputDto[];
  suppliers: FornitoreDto[];
}

const initialState: InitialState = {
  passiveCycleOptions: undefined,
  orders: [],
  filtersOrdersUsed: undefined,
  associableOtherCostsProjects: [],
  suppliers: [],
};

export const passiveCycleReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getPassiveCycleOptions.fulfilled, (state, action) => {
      state.passiveCycleOptions = action.payload;
    })
    .addCase(createOrder.fulfilled, (state, action) => {
      // AppToastService.success('Order created successfully');
    })
    .addCase(searchOrders.fulfilled, (state, action) => {
      state.orders = action.payload?.contractOrderOutputDtoList || [];
    })
    .addCase(saveFiltersOrdersUsed, (state, action) => {
      state.filtersOrdersUsed = action.payload;
    })
    .addCase(getOtherAssociatibleCosts.fulfilled, (state, action) => {
      state.associableOtherCostsProjects = action.payload?.projectCicloPassivoOutputDtoList || [];
    })
    .addCase(getSuppliers.fulfilled, (state, action) => {
      state.suppliers = action.payload || [];
    })
    .addCase(getSupplierById.fulfilled, (state, action) => {
      state.suppliers = action.payload ? [action.payload] : [];
    });
});
