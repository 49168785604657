import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../core/store';

const projectDeailReducer = (state: RootState) => state.private.dettaglioProgetto;

export const costiSelector = createSelector(projectDeailReducer, (progetto) => progetto.costi);
export const ruoliSelector = createSelector(projectDeailReducer, (progetto) => progetto.ruoli);
export const allUsersSelector = createSelector(projectDeailReducer, (progetto) => progetto.allUsers);
export const teamDataSelector = createSelector(projectDeailReducer, (progetto) => progetto.teamData);
export const costsDataSelector = createSelector(projectDeailReducer, (progetto) => progetto.costsData);
export const revenuesToBeConsolidatedSelector = createSelector(
  projectDeailReducer,
  (progetto) => progetto.revenuesToBeConsolidated
);
export const competenzeComplessiveSelector = createSelector(
  projectDeailReducer,
  (progetto) => progetto.competenzeComplessive
);
export const competenzeAnnoCorrenteSelector = createSelector(
  projectDeailReducer,
  (progetto) => progetto.competenzeAnnoCorrente
);
export const associatedContractsSelector = createSelector(
  projectDeailReducer,
  (progetto) => progetto.associatedContracts
);
