import { Dropdown, MenuProps, Tooltip } from 'antd';
import { getAllocationLavorate } from '../../_utils/revenue-plan.utils';
import { StaffExtendedDto } from '@api/client';
import { useTranslation } from 'react-i18next';

interface Props {
  index: number;
  idProject: number | undefined;
  totalGUPlanned: number[];
  fullAllocations: Partial<StaffExtendedDto>[] | null | undefined;
  clearForecastDays: (index: number) => void;
  handleShowMoveDaysModal: (index: number) => void;
  deleteCollaborator: (index: number, idProject: number | undefined) => void;
}

const ActionsMenuComponent: React.FC<Props> = ({
  index,
  idProject,
  totalGUPlanned,
  fullAllocations,
  clearForecastDays,
  handleShowMoveDaysModal,
  deleteCollaborator,
}) => {
  const { t } = useTranslation();
  const handleMenuClick: (rowIndex: number) => MenuProps['onClick'] = (rowIndex) => (e) => {
    switch (e.key) {
      case '1':
        clearForecastDays(rowIndex);
        break;
      case '2':
        handleShowMoveDaysModal(rowIndex);
        break;
      case '3':
        deleteCollaborator(rowIndex, idProject);
        break;
    }
  };

  const generateMenuItems = (rowIndex: number) => {
    const isDisabled = Number(totalGUPlanned?.[rowIndex]) <= 0 ? 'lightgrey' : 'text-blue-links';
    return [
      {
        label: t('project-detail.clean-forecast-days'),
        key: '1',
        icon: <i className={`icon-pulisci text-xl ${isDisabled}`} />,
        danger: false,
        disabled: Number(totalGUPlanned?.[rowIndex]) <= 0,
      },
      {
        label: t('project-detail.move-days.label'),
        key: '2',
        icon: <i className={`icon-sposta-giorni text-xl ${isDisabled}`} />,
        danger: false,
        disabled: Number(totalGUPlanned?.[rowIndex]) <= 0,
      },
      {
        label: t('project-detail.delete-collaborator'),
        key: '3',
        icon: <i className="icon-delete text-xl" />,
        danger: true,
        disabled: !isDeleteCollaboratorEnabled(rowIndex),
      },
    ];
  };

  const menuProps = (rowIndex: number) => ({
    items: generateMenuItems(rowIndex),
    onClick: handleMenuClick(rowIndex),
  });

  const isDeleteCollaboratorEnabled = (index: number) => {
    const allocationLavorate =
      Number(getAllocationLavorate(fullAllocations, index)?.reduce((acc, el) => acc + (el.actualAllocation ?? 0), 0)) ??
      0;
    // se il collaboratore ha già rendicontato non posso cancellarlo
    if (allocationLavorate !== 0) {
      return false;
    }
    // se è un collaboratore fittizio creato e FE ed è l'unico in tabella non posso cancellarlo
    if (fullAllocations?.length === 1 && !fullAllocations?.[0]?.idPps && !fullAllocations?.[0]?.idPpt) {
      return false;
    }
    return true;
  };

  return (
    <Tooltip title={'Actions'} color="white" key={`actions-${index}`}>
      <Dropdown menu={menuProps(index)} trigger={['click']}>
        <i
          className="fa-solid fa-ellipsis-vertical text-blue-links pt-2 pe-3 ps-1"
          role="button"
          onClick={(e) => e.preventDefault()}
        ></i>
      </Dropdown>
    </Tooltip>
  );
};

export default ActionsMenuComponent;
