/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AziendaFromJSON, AziendaToJSON, } from './Azienda';
import { FornitoreFromJSON, FornitoreToJSON, } from './Fornitore';
import { OrderCategoryFromJSON, OrderCategoryToJSON, } from './OrderCategory';
import { SedeFromJSON, SedeToJSON, } from './Sede';
/**
 * @export
 */
export const CicloPassivoFieldsDtoExpenseItemEnumListEnum = {
    Consulenze: 'CONSULENZE',
    Distacco: 'DISTACCO',
    CostiPerLaRivendita: 'COSTI_PER_LA_RIVENDITA',
    Formazione: 'FORMAZIONE',
    GovernanceEControllo: 'GOVERNANCE_E_CONTROLLO',
    Ict: 'ICT',
    Marketing: 'MARKETING',
    ServiziProfessionali: 'SERVIZI_PROFESSIONALI',
    Uffici: 'UFFICI',
    AltriCostiDelPersonaleDiProduzione: 'ALTRI_COSTI_DEL_PERSONALE_DI_PRODUZIONE'
};
/**
 * @export
 */
export const CicloPassivoFieldsDtoOrderContractStatusEnumListEnum = {
    Attivo: 'ATTIVO',
    NonAttivo: 'NON_ATTIVO'
};
/**
 * @export
 */
export const CicloPassivoFieldsDtoOrderProcessingStatusEnumListEnum = {
    Avviato: 'AVVIATO',
    NonAvviato: 'NON_AVVIATO',
    Concluso: 'CONCLUSO'
};
/**
 * @export
 */
export const CicloPassivoFieldsDtoOrderTypeEnumListEnum = {
    Attivo: 'ATTIVO',
    Passivo: 'PASSIVO'
};
/**
 * Check if a given object implements the CicloPassivoFieldsDto interface.
 */
export function instanceOfCicloPassivoFieldsDto(value) {
    return true;
}
export function CicloPassivoFieldsDtoFromJSON(json) {
    return CicloPassivoFieldsDtoFromJSONTyped(json, false);
}
export function CicloPassivoFieldsDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'expenseItemEnumList': json['expenseItemEnumList'] == null ? undefined : json['expenseItemEnumList'],
        'orderContractStatusEnumList': json['orderContractStatusEnumList'] == null ? undefined : json['orderContractStatusEnumList'],
        'orderProcessingStatusEnumList': json['orderProcessingStatusEnumList'] == null ? undefined : json['orderProcessingStatusEnumList'],
        'orderTypeEnumList': json['orderTypeEnumList'] == null ? undefined : json['orderTypeEnumList'],
        'fornitoriList': json['fornitoriList'] == null ? undefined : (json['fornitoriList'].map(FornitoreFromJSON)),
        'aziendaList': json['aziendaList'] == null ? undefined : (json['aziendaList'].map(AziendaFromJSON)),
        'orderCategoryList': json['orderCategoryList'] == null ? undefined : (json['orderCategoryList'].map(OrderCategoryFromJSON)),
        'sedeList': json['sedeList'] == null ? undefined : (json['sedeList'].map(SedeFromJSON)),
    };
}
export function CicloPassivoFieldsDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'expenseItemEnumList': value['expenseItemEnumList'],
        'orderContractStatusEnumList': value['orderContractStatusEnumList'],
        'orderProcessingStatusEnumList': value['orderProcessingStatusEnumList'],
        'orderTypeEnumList': value['orderTypeEnumList'],
        'fornitoriList': value['fornitoriList'] == null ? undefined : (value['fornitoriList'].map(FornitoreToJSON)),
        'aziendaList': value['aziendaList'] == null ? undefined : (value['aziendaList'].map(AziendaToJSON)),
        'orderCategoryList': value['orderCategoryList'] == null ? undefined : (value['orderCategoryList'].map(OrderCategoryToJSON)),
        'sedeList': value['sedeList'] == null ? undefined : (value['sedeList'].map(SedeToJSON)),
    };
}
