import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../core/store/hooks';
import { AppToastService } from '../../../../../shared/design-system/components/app-toast/app-toast.service';
import { deleteSupplier, getSuppliers } from '../../_redux/actions';
import { Dispatch, SetStateAction, useState } from 'react';
import { FornitoreDto } from '@api/client';

const useDeleteSupplier = (setAllSuppliers: Dispatch<SetStateAction<FornitoreDto[]>>) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [idToDelete, setIdToDelete] = useState<number | undefined>(undefined);

  const deleteSupplierById = () => {
    dispatch(deleteSupplier(idToDelete!)).then((res) => {
      if (res) {
        dispatch(getSuppliers())
          .unwrap()
          .then((res) => {
            if (res) {
              setAllSuppliers(res);
            }
          });
        AppToastService.success(t('common.action-completed-successfully'));
      }
    });
  };

  return { deleteSupplierById, setIdToDelete };
};

export default useDeleteSupplier;
