import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import DashboardPage from './dashboard';
import { PathEnum } from '../../core/router/path.enum';
import AppHeader from '../../shared/design-system/components/app-header';
import AppSidebar from '../../shared/design-system/components/app-sidebar';
import Projects from './project-list';
import ProjectDetail from './project-list/project-detail';
import AppLoader from '../../shared/design-system/components/app-loader';
import AppFooter from '../../shared/design-system/components/app-footer';
import BreadCrumb from '../../shared/design-system/components/breadcrumb';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../core/store/hooks';
import { authRenew } from '../auth/_redux/actions';
import ModalSessionExpired from './ModalSessionExpired';
import Wfm from './wfm';
import { Session } from '../../enums/project.enum';
import ManagementTool from './management-tools';
import CrudMenu from './crud';
import NotFoundPage from './not-found-page';
import WidgetAiNlux from '../../shared/design-system/components/app-widget-ai-nlux';
import PassiveCycle from './passive-cycle';
import { getUserInfo } from '../../shared/_components/delegate-management/_redux/actions';
import { StorageKeysEnum } from '../../enums/storageKeys.enum';
import DelegateManagementPage from './delegate-management';
import OrderDetail from './passive-cycle/order-list/order-detail';

const PrivatePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const showModalRef = useRef(showModal);
  const lastInteractionTime = useRef(Date.now());
  const timeSinceLastRenew = useRef(0);
  const REFRESH_TOKEN_INTERVAL = 300000; // 5 minuti
  const EXPIRATION_TOKEN_INTERVAL = 2700000; // 45 minuti

  useEffect(() => {
    dispatch(getUserInfo())
      .unwrap()
      .then((res) => {
        if (res) {
          localStorage.setItem(StorageKeysEnum.IS_HCC, JSON.stringify(res.canDefineDesignatedUserForHimself));
        }
      });

    const handleInteractions = () => {
      lastInteractionTime.current = Date.now();
    };

    const interval = setInterval(() => {
      const currentTime = Date.now();
      const timeSinceLastInteraction = currentTime - lastInteractionTime.current;

      if (timeSinceLastInteraction < REFRESH_TOKEN_INTERVAL) {
        if (timeSinceLastRenew.current >= REFRESH_TOKEN_INTERVAL && showModalRef.current === false) {
          // Controlla se sono trascorsi almeno 10 minuti dall'ultima chiamata a authRenew
          dispatch(authRenew());
          timeSinceLastRenew.current = 0; // Reimposta il tempo trascorso dall'ultima chiamata a authRenew
        }
      } else if (timeSinceLastInteraction > EXPIRATION_TOKEN_INTERVAL) {
        // 15 secondi
        setShowModal(true);
        showModalRef.current = true;
      }

      timeSinceLastRenew.current += 1000;
    }, 1000);

    window.addEventListener('mousemove', handleInteractions);
    window.addEventListener('keypress', handleInteractions);

    return () => {
      clearInterval(interval);
      window.removeEventListener('mousemove', handleInteractions);
      window.removeEventListener('keypress', handleInteractions);
    };
  }, []);

  return (
    <div id="wrapper">
      <AppSidebar />
      <div id="content-wrapper" className="d-flex flex-column shadow-left">
        <div id="content">
          <AppHeader />
          <BreadCrumb />
          <AppLoader />
          <Outlet />
          <div className="container-fluid">
            <Routes>
              <Route path="" element={<Navigate to={PathEnum.PROGETTI} />} />
              <Route path={`${PathEnum.DASHBOARD}`} element={<DashboardPage />} />
              <Route path={PathEnum.PROGETTI} element={<Projects />}></Route>
              <Route path={`${PathEnum.PROGETTI}/:id`} element={<ProjectDetail />} />
              <Route path={PathEnum.CICLO_PASSIVO} element={<PassiveCycle />} />
              <Route path={`${PathEnum.CICLO_PASSIVO}/:id`} element={<OrderDetail />} />
              <Route path={PathEnum.CDG} element={<ManagementTool session={Session.CDG} key={Session.CDG} />} />
              <Route path={PathEnum.CL} element={<ManagementTool session={Session.CL} key={Session.CL} />} />
              <Route path={PathEnum.PL} element={<ManagementTool session={Session.PL} key={Session.PL} />} />
              <Route path={PathEnum.CRUD} element={<CrudMenu />} />
              <Route path={PathEnum.WFM} element={<Wfm />} />
              <Route path={PathEnum.DELEGATE_MANAGEMENT} element={<DelegateManagementPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </div>
        <AppFooter />
      </div>

      <ModalSessionExpired showModal={showModal} setShowModal={setShowModal} />
      <WidgetAiNlux />
    </div>
  );
};
export default PrivatePage;
