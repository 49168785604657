import { getFormattedDateString } from '../../../../../shared/utils/common.utils';
import { DeleteMonthRequest, StaffExtendedDto } from '@api/client';
import { useState } from 'react';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckboxProps } from 'antd';
import { useAppDispatch } from '../../../../../core/store/hooks';
import { deleteMonths } from '../_redux/actions';
import { MonthOption } from '../_models/monthOption';

const useSpreadOutDays = (
  fullAllocations: Partial<StaffExtendedDto>[] | null | undefined,
  currentYear: number | 'all',
  totalGUPlanned: number[],
  idProject: number | undefined,
  setFullAllocations: React.Dispatch<React.SetStateAction<Partial<StaffExtendedDto>[] | null | undefined>>,
  filterAllocationsByYear: (year: number | 'all', allocations: Partial<StaffExtendedDto>[] | null | undefined) => void
) => {
  const dispatch = useAppDispatch();
  const monthOptionsObj: MonthOption[] =
    fullAllocations?.[0]?.allocazioni
      ?.filter((alloc) => alloc.editEnable)
      ?.map((el) => ({
        label: el.month,
        value: getFormattedDateString(el.month, el.year),
      })) ?? [];

  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(monthOptionsObj.map((el) => el.value));

  const onChangeCheckedList = (list: any[]) => {
    const lastToggledItem =
      list.find((item) => !checkedList.includes(item)) || checkedList.find((item) => !list.includes(item));
    if (typeof lastToggledItem === 'number') {
      const year = lastToggledItem;
      const isSelectingYear = !checkedList.includes(year);
      if (isSelectingYear) {
        const monthsOfYear = monthOptionsObj
          .filter((option) => option.value.includes(year.toString()))
          .map((option) => option.value);
        setCheckedList([...checkedList, ...monthsOfYear, year]);
      } else {
        const monthsOfYear = monthOptionsObj
          .filter((option) => option.value.includes(year.toString()))
          .map((option) => option.value);
        setCheckedList(checkedList.filter((item) => !monthsOfYear.includes(item.toString()) && item !== year));
      }
    } else {
      setCheckedList(list);
    }
  };

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    const monthOptions = monthOptionsObj.map((el) => el.value);
    setCheckedList(e.target.checked ? monthOptions : []);
    if (typeof e.target.value === 'number') {
      setCheckedList(monthOptions.map((el) => el.toString().includes(e.target.value)));
    }
  };

  const clearForecastDays = (index: number) => {
    let newFullAllocations = fullAllocations?.map((el) => {
      if (el.allocazioni?.[0].id == fullAllocations?.[index].allocazioni?.[0].id) {
        return {
          ...el,
          allocazioni: el.allocazioni?.map((el) => {
            if (el.editEnable) {
              return {
                ...el,
                actualAllocation: 0,
              };
            }
            return el;
          }),
        };
      }
      return el;
    });

    setFullAllocations(newFullAllocations);
    filterAllocationsByYear(currentYear, newFullAllocations);
  };

  const clearForecastDaysFromID = (userId: string) => {
    let newFullAllocations = fullAllocations?.map((el) => {
      if (el.userId === userId) {
        return {
          ...el,
          allocazioni: el.allocazioni?.map((el) => {
            if (el.editEnable) {
              return {
                ...el,
                actualAllocation: 0,
              };
            }
            return el;
          }),
        };
      }
      return el;
    });

    setFullAllocations(newFullAllocations);
    filterAllocationsByYear(currentYear, newFullAllocations);
  };

  const spalmaGiorni = (index: number) => {
    const totGiorni = totalGUPlanned?.[index] ?? 0;
    let newFullAllocations = fullAllocations?.map((el) => {
      if (el.allocazioni?.[0].id === fullAllocations?.[index].allocazioni?.[0].id) {
        return {
          ...el,
          allocazioni: el.allocazioni?.map((el) => {
            if (checkedList.includes(getFormattedDateString(el.month, el.year))) {
              const checkedListLenght = checkedList.filter((item, index) => {
                if (typeof item === 'string') {
                  return checkedList.indexOf(item) === index;
                }
                return false;
              }).length;
              return {
                ...el,
                actualAllocation: Number((totGiorni / checkedListLenght).toFixed(2)),
              };
            }
            if (el.editEnable) {
              return {
                ...el,
                actualAllocation: 0,
              };
            }
            return el;
          }),
        };
      }
      return el;
    });
    setFullAllocations(newFullAllocations);
    filterAllocationsByYear(currentYear, newFullAllocations);
  };

  const removeMonths = () => {
    let newFullAllocations = fullAllocations?.map((el) => {
      return {
        ...el,
        allocazioni: el.allocazioni?.filter((el) => !checkedList.includes(getFormattedDateString(el.month, el.year))),
      };
    });
    setFullAllocations(newFullAllocations);
    filterAllocationsByYear(currentYear, newFullAllocations);

    if (idProject) {
      const monthsYears = getMonthsToDelete();
      const payload: DeleteMonthRequest = {
        deleteMonthsRequestDto: {
          idProject,
          monthsYears,
        },
      };
      dispatch(deleteMonths(payload));
    }
  };

  const getMonthsToDelete = () => {
    let stringArray: string[] = checkedList.filter((item): item is string => typeof item === 'string');

    // Eliminare i duplicati
    let uniqueArray: string[] = [];
    stringArray.forEach((item) => {
      if (!uniqueArray.includes(item)) {
        uniqueArray.push(item);
      }
    });

    // Mappare le stringhe in oggetti
    let monthsYears = uniqueArray.map((dateStr) => {
      let [month, year] = dateStr.split(' ');
      let monthNumber;

      // Convertire il nome del mese in numero
      switch (month) {
        case 'January':
          monthNumber = 1;
          break;
        case 'February':
          monthNumber = 2;
          break;
        case 'March':
          monthNumber = 3;
          break;
        case 'April':
          monthNumber = 4;
          break;
        case 'May':
          monthNumber = 5;
          break;
        case 'June':
          monthNumber = 6;
          break;
        case 'July':
          monthNumber = 7;
          break;
        case 'August':
          monthNumber = 8;
          break;
        case 'September':
          monthNumber = 9;
          break;
        case 'October':
          monthNumber = 10;
          break;
        case 'November':
          monthNumber = 11;
          break;
        case 'December':
          monthNumber = 12;
          break;
      }

      return { month: monthNumber, year: parseInt(year) };
    });
    return monthsYears;
  };

  return {
    monthOptionsObj,
    checkedList,
    onChangeCheckedList,
    onCheckAllChange,
    spalmaGiorni,
    removeMonths,
    clearForecastDays,
    clearForecastDaysFromID,
  };
};

export default useSpreadOutDays;
// import { getFormattedDateString } from '../../../../../shared/utils/common.utils';
// import { StaffExtendedDto } from '@api/client';
// import { useState } from 'react';
// import { CheckboxOptionType, CheckboxValueType } from 'antd/es/checkbox/Group';
// import { Checkbox, CheckboxProps, GetProp } from 'antd';

// const useSpreadOutDays = (
//   fullAllocations: Partial<StaffExtendedDto>[] | null | undefined,
//   currentYear: number | 'all',
//   totalGUPlanned: number[],
//   setFullAllocations: React.Dispatch<React.SetStateAction<Partial<StaffExtendedDto>[] | null | undefined>>,
//   filterAllocationsByYear: (year: number | 'all', allocations: Partial<StaffExtendedDto>[] | null | undefined) => void,
// ) => {
//   const monthOptionsObj =
//     fullAllocations?.[0]?.allocazioni
//       ?.filter((alloc) => alloc.editEnable)
//       ?.map((el) => ({
//         label: el.month,
//         value: getFormattedDateString(el.month, el.year),
//       })) ?? [];

//   const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(monthOptionsObj.map((el) => el.value));

//   const onChange = (list: CheckboxValueType[]) => {
//     setCheckedList(list);
//     console.log(list);
//   };

//   const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
//     const monthOptions = monthOptionsObj.map((el) => el.value);
//     setCheckedList(e.target.checked ? monthOptions : []);
//   };
//   const [checkedKeys, setCheckedKeys] = useState<any>([]);

//   const spalmaGiorni = (index: number) => {
//     const totGiorni = totalGUPlanned?.[index] ?? 0;
//     let newFullAllocations = fullAllocations?.map((el) => {
//       if (el.allocazioni?.[0].id == fullAllocations?.[index].allocazioni?.[0].id) {
//         return {
//           ...el,
//           allocazioni: el.allocazioni?.map((el) => {
//             if (checkedKeys.includes(getFormattedDateString(el.month, el.year))) {
//               return {
//                 ...el,
//                 actualAllocation: Number((totGiorni / checkedKeys.length).toFixed(2)),
//               };
//             }
//             if (el.editEnable) {
//               return {
//                 ...el,
//                 actualAllocation: 0,
//               };
//             }
//             return el;
//           }),
//         };
//       }
//       return el;
//     });
//     setFullAllocations(newFullAllocations);
//     filterAllocationsByYear(currentYear, newFullAllocations);
//   };
//   /////////////////////////////////////////////////////////

//   return {
//     monthOptionsObj,
//     checkedList,
//     onChange,
//     onCheckAllChange,
//     checkedKeys,
//     setCheckedKeys,
//     spalmaGiorni,
//   };
// };

// export default useSpreadOutDays;
