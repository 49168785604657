import AppCard from '../../../../../../../shared/design-system/components/app-card';
import { useTranslation } from 'react-i18next';
import { ContractOrderOutputDto, UserDto } from '@api/client';
import OrderDataStatic from './order-data-static';
import { Tooltip } from 'antd';
import { useState } from 'react';
import OrderDataEdit from './order-data-edit';

interface Props {
  order: ContractOrderOutputDto;
  allUsers: UserDto[] | null;
}

const OrderData: React.FC<Props> = ({ order, allUsers }) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const HeaderElement = (
    <Tooltip placement="top" title="Edit" color="teal">
      <button
        onClick={() => {
          setEditMode(!editMode);
        }}
        className="btn-icon"
        style={{ height: '10px' }}
      >
        <i className={'icon-edit text-teal-links me-1'} />
      </button>
    </Tooltip>
  );

  return (
    <AppCard
      title={t('Order data')}
      headerElement={HeaderElement}
      headerClassname="d-flex justify-content-between"
      className="border-left-primary mt-3"
    >
      {editMode ? (
        <OrderDataEdit order={order} allUsers={allUsers} setEditMode={setEditMode} />
      ) : (
        <OrderDataStatic order={order} allUsers={allUsers} />
      )}
    </AppCard>
  );
};

export default OrderData;
