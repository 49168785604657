import { useEffect, useState } from 'react';
import { ProjectCdgData, RevenueAndCostsData } from './useGetAndMapManagementToolProjects';

const useGetExportableExcelData = (
  projects: Partial<ProjectCdgData>[],
  revenueAndCostsData: Partial<RevenueAndCostsData>[]
) => {
  const [excelData, setExcelData] = useState<any[]>([]);

  useEffect(() => {
    getExportableExcelData();
  }, [projects]);

  const getExportableExcelData = () => {
    const mappedProjects = projects.map((item) => {
      return {
        [`${item.id?.label}`]: item.id?.value,
        [`${item.name?.label}`]: item.name?.value,
        [`${item.clientLead?.label}`]: item.clientLead?.value,
        [`${item.projectLead?.label}`]: item.projectLead?.value,
      };
    });
    const mappedProjectsWithRevenueAndCosts = mappedProjects.map((item, index) => {
      return {
        ...item,
        // [`${revenueAndCostsData[index].previousYearRevenue?.label} ${revenueAndCostsData[index].previousYearRevenue?.month}`]:
        //   revenueAndCostsData[index].previousYearRevenue?.value,
        // [`${revenueAndCostsData[index].previousYearCosts?.label} ${revenueAndCostsData[index].previousYearCosts?.month}`]:
        //   revenueAndCostsData[index].previousYearCosts?.value,
        // [`${revenueAndCostsData[index].januaryCurrentYear?.label} ${revenueAndCostsData[index].januaryCurrentYear?.month}`]:
        //   revenueAndCostsData[index].januaryCurrentYear?.value,
        // [`${revenueAndCostsData[index].januaryCosts?.label} ${revenueAndCostsData[index].januaryCosts?.month}`]:
        //   revenueAndCostsData[index].januaryCosts?.value,
        // [`${revenueAndCostsData[index].februaryCurrentYear?.label} ${revenueAndCostsData[index].februaryCurrentYear?.month}`]:
        //   revenueAndCostsData[index].februaryCurrentYear?.value,
        // [`${revenueAndCostsData[index].februaryCosts?.label} ${revenueAndCostsData[index].februaryCosts?.month}`]:
        //   revenueAndCostsData[index].februaryCosts?.value,
        // [`${revenueAndCostsData[index].marchCurrentYear?.label} ${revenueAndCostsData[index].marchCurrentYear?.month}`]:
        //   revenueAndCostsData[index].marchCurrentYear?.value,
        // [`${revenueAndCostsData[index].marchCosts?.label} ${revenueAndCostsData[index].marchCosts?.month}`]:
        //   revenueAndCostsData[index].marchCosts?.value,
        // [`${revenueAndCostsData[index].aprilCurrentYear?.label} ${revenueAndCostsData[index].aprilCurrentYear?.month}`]:
        //   revenueAndCostsData[index].aprilCurrentYear?.value,
        // [`${revenueAndCostsData[index].aprilCosts?.label} ${revenueAndCostsData[index].aprilCosts?.month}`]:
        //   revenueAndCostsData[index].aprilCosts?.value,
        // [`${revenueAndCostsData[index].mayCurrentYear?.label} ${revenueAndCostsData[index].mayCurrentYear?.month}`]:
        //   revenueAndCostsData[index].mayCurrentYear?.value,
        // [`${revenueAndCostsData[index].mayCosts?.label} ${revenueAndCostsData[index].mayCosts?.month}`]:
        //   revenueAndCostsData[index].mayCosts?.value,
        // [`${revenueAndCostsData[index].juneCurrentYear?.label} ${revenueAndCostsData[index].juneCurrentYear?.month}`]:
        //   revenueAndCostsData[index].juneCurrentYear?.value,
        // [`${revenueAndCostsData[index].juneCosts?.label} ${revenueAndCostsData[index].juneCosts?.month}`]:
        //   revenueAndCostsData[index].juneCosts?.value,
        // [`${revenueAndCostsData[index].julyCurrentYear?.label} ${revenueAndCostsData[index].julyCurrentYear?.month}`]:
        //   revenueAndCostsData[index].julyCurrentYear?.value,
        // [`${revenueAndCostsData[index].julyCosts?.label} ${revenueAndCostsData[index].julyCosts?.month}`]:
        //   revenueAndCostsData[index].julyCosts?.value,
        // [`${revenueAndCostsData[index].augustCurrentYear?.label} ${revenueAndCostsData[index].augustCurrentYear?.month}`]:
        //   revenueAndCostsData[index].augustCurrentYear?.value,
        // [`${revenueAndCostsData[index].augustCosts?.label} ${revenueAndCostsData[index].augustCosts?.month}`]:
        //   revenueAndCostsData[index].augustCosts?.value,
        // [`${revenueAndCostsData[index].septemberCurrentYear?.label} ${revenueAndCostsData[index].septemberCurrentYear?.month}`]:
        //   revenueAndCostsData[index].septemberCurrentYear?.value,
        // [`${revenueAndCostsData[index].septemberCosts?.label} ${revenueAndCostsData[index].septemberCosts?.month}`]:
        //   revenueAndCostsData[index].septemberCosts?.value,
        // [`${revenueAndCostsData[index].octoberCurrentYear?.label} ${revenueAndCostsData[index].octoberCurrentYear?.month}`]:
        //   revenueAndCostsData[index].octoberCurrentYear?.value,
        // [`${revenueAndCostsData[index].octoberCosts?.label} ${revenueAndCostsData[index].octoberCosts?.month}`]:
        //   revenueAndCostsData[index].octoberCosts?.value,
        // [`${revenueAndCostsData[index].novemberCurrentYear?.label} ${revenueAndCostsData[index].novemberCurrentYear?.month}`]:
        //   revenueAndCostsData[index].novemberCurrentYear?.value,
        // [`${revenueAndCostsData[index].novemberCosts?.label} ${revenueAndCostsData[index].novemberCosts?.month}`]:
        //   revenueAndCostsData[index].novemberCosts?.value,
        // [`${revenueAndCostsData[index].automaticRevenue?.label} ${revenueAndCostsData[index].automaticRevenue?.month}`]:
        //   revenueAndCostsData[index].automaticRevenue?.value,
        [`${revenueAndCostsData[index].actualRevenue?.label} ${revenueAndCostsData[index].actualRevenue?.month}`]:
          revenueAndCostsData[index].actualRevenue?.value,
        [`${revenueAndCostsData[index].actualOtherCosts?.label} ${revenueAndCostsData[index].actualOtherCosts?.month}`]:
          revenueAndCostsData[index].actualOtherCosts?.value,
        [`${revenueAndCostsData[index].actualHrCosts?.label} ${revenueAndCostsData[index].actualHrCosts?.month}`]:
          revenueAndCostsData[index].actualHrCosts?.value,
      };
    });
    setExcelData(mappedProjectsWithRevenueAndCosts);
  };
  return { excelData };
};

export default useGetExportableExcelData;
