/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { BaselineOffertaDtoFromJSON, BillingDataResultFromJSON, CompetenzeProgettoDtoFromJSON, DetailContractExternalCostDtoFromJSON, DettagliProgettoDtoFromJSON, DettagliProgettoDtoToJSON, DettaglioProjectDtoFromJSON, FilterDtoToJSON, ListNoteDtoFromJSON, NoteDtoFromJSON, NoteDtoToJSON, SearchFilterDtoFromJSON, SearchProjectResponseDtoFromJSON, } from '../models/index';
/**
 *
 */
export class ControllerProjectApi extends runtime.BaseAPI {
    /**
     * Cambia lo stato di un progetto in REPORTING_CLOSED
     */
    closeRendicontazioneRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling closeRendicontazione().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/project/{projectId}/closeRendicontazione`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Cambia lo stato di un progetto in REPORTING_CLOSED
     */
    closeRendicontazione(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.closeRendicontazioneRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera la baseline offerta del progetto tramite id
     */
    getBaselineOffertaRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling getBaselineOfferta().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/project/{projectId}/baseline-offerta`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => BaselineOffertaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera la baseline offerta del progetto tramite id
     */
    getBaselineOfferta(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getBaselineOffertaRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera le competenze complessive dell\'anno corrente per il progetto
     */
    getCompetenzeAnnoCorrenteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling getCompetenzeAnnoCorrente().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/project/{projectId}/competenze-anno-corrente`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CompetenzeProgettoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera le competenze complessive dell\'anno corrente per il progetto
     */
    getCompetenzeAnnoCorrente(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getCompetenzeAnnoCorrenteRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera le competenze complessive del progetto tramite id
     */
    getCompetenzeComplessiveRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling getCompetenzeComplessive().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/project/{projectId}/competenze-complessive`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CompetenzeProgettoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera le competenze complessive del progetto tramite id
     */
    getCompetenzeComplessive(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getCompetenzeComplessiveRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Prende in input l\'ID del progetto e restituisce tutti gli External Costs associati, raggruppati per tripletta (groupId, quoteId, costTypeId).
     * Recupera gli i contratti associati agli External Costs tramite il Project ID
     */
    getExternalCostsByProjectRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling getExternalCostsByProject().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/project/project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DetailContractExternalCostDtoFromJSON));
        });
    }
    /**
     * Prende in input l\'ID del progetto e restituisce tutti gli External Costs associati, raggruppati per tripletta (groupId, quoteId, costTypeId).
     * Recupera gli i contratti associati agli External Costs tramite il Project ID
     */
    getExternalCostsByProject(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getExternalCostsByProjectRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera i dati di fatturazione legati all\'opportunity
     * Dati di fatturazione
     */
    projectBillingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling projectBilling().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/project/{projectId}/billing-data`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => BillingDataResultFromJSON(jsonValue));
        });
    }
    /**
     * Recupera i dati di fatturazione legati all\'opportunity
     * Dati di fatturazione
     */
    projectBilling(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectBillingRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Modifica i dati non null del progetto passati in input, basandosi sui permessi dell\'utente loggato
     * Modifica dati progetto
     */
    projectDataUpdateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['dettagliProgettoDto'] == null) {
                throw new runtime.RequiredError('dettagliProgettoDto', 'Required parameter "dettagliProgettoDto" was null or undefined when calling projectDataUpdate().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/project/edit-project`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: DettagliProgettoDtoToJSON(requestParameters['dettagliProgettoDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => DettagliProgettoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Modifica i dati non null del progetto passati in input, basandosi sui permessi dell\'utente loggato
     * Modifica dati progetto
     */
    projectDataUpdate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectDataUpdateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Elimina il messaggio passandogli l\'id del messaggio
     * Elimina messaggio
     */
    projectDeleteMessageRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['messageId'] == null) {
                throw new runtime.RequiredError('messageId', 'Required parameter "messageId" was null or undefined when calling projectDeleteMessage().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/project/messages/{messageId}/delete`.replace(`{${"messageId"}}`, encodeURIComponent(String(requestParameters['messageId']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => NoteDtoFromJSON(jsonValue));
        });
    }
    /**
     * Elimina il messaggio passandogli l\'id del messaggio
     * Elimina messaggio
     */
    projectDeleteMessage(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectDeleteMessageRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Prende in input l\'ID e restituisce l\'output dell\'elemento con quell\'ID
     * Recupera il progetto tramite id
     */
    projectDetailRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling projectDetail().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => DettaglioProjectDtoFromJSON(jsonValue));
        });
    }
    /**
     * Prende in input l\'ID e restituisce l\'output dell\'elemento con quell\'ID
     * Recupera il progetto tramite id
     */
    projectDetail(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectDetailRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera tutti i progetti presenti nel sistema
     * Recupera tutti i progetti in evidenza
     */
    projectEvidenzaRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/project`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => SearchProjectResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera tutti i progetti presenti nel sistema
     * Recupera tutti i progetti in evidenza
     */
    projectEvidenza(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectEvidenzaRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera i messaggi legati al progetto
     * Recupera i messaggi legati al progetto
     */
    projectGetMessageRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling projectGetMessage().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/project/{projectId}/messages`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ListNoteDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera i messaggi legati al progetto
     * Recupera i messaggi legati al progetto
     */
    projectGetMessage(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectGetMessageRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Inserisci il messaggio passandogli l\'id del progetto e il testo del messaggio
     * Inserisci messaggio
     */
    projectInsertMessageRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling projectInsertMessage().');
            }
            if (requestParameters['noteDto'] == null) {
                throw new runtime.RequiredError('noteDto', 'Required parameter "noteDto" was null or undefined when calling projectInsertMessage().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/project/{projectId}/insertMessage`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: NoteDtoToJSON(requestParameters['noteDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => NoteDtoFromJSON(jsonValue));
        });
    }
    /**
     * Inserisci il messaggio passandogli l\'id del progetto e il testo del messaggio
     * Inserisci messaggio
     */
    projectInsertMessage(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectInsertMessageRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera tutti i progetti presenti nel sistema tramite filtri
     * Recupera tutti i progetti tramite i filtri
     */
    projectSearchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['filterDto'] == null) {
                throw new runtime.RequiredError('filterDto', 'Required parameter "filterDto" was null or undefined when calling projectSearch().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/project/search`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: FilterDtoToJSON(requestParameters['filterDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => SearchProjectResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera tutti i progetti presenti nel sistema tramite filtri
     * Recupera tutti i progetti tramite i filtri
     */
    projectSearch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectSearchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera tutti i filtri di ricerca per un progetto
     * Recupera tutti i filtri di ricerca
     */
    projectSearchFilterRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/project/filtri-ricerca`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => SearchFilterDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera tutti i filtri di ricerca per un progetto
     * Recupera tutti i filtri di ricerca
     */
    projectSearchFilter(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectSearchFilterRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Modifica il messaggio passandogli l\'id del messaggio e il testo del messaggio
     * Modifica messaggio
     */
    projectUpdateMessageRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['messageId'] == null) {
                throw new runtime.RequiredError('messageId', 'Required parameter "messageId" was null or undefined when calling projectUpdateMessage().');
            }
            if (requestParameters['noteDto'] == null) {
                throw new runtime.RequiredError('noteDto', 'Required parameter "noteDto" was null or undefined when calling projectUpdateMessage().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/project/messages/{messageId}/update`.replace(`{${"messageId"}}`, encodeURIComponent(String(requestParameters['messageId']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: NoteDtoToJSON(requestParameters['noteDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => NoteDtoFromJSON(jsonValue));
        });
    }
    /**
     * Modifica il messaggio passandogli l\'id del messaggio e il testo del messaggio
     * Modifica messaggio
     */
    projectUpdateMessage(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectUpdateMessageRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
