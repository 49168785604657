import { useState } from 'react';

const useHandleVisibleRows = () => {
  const initialVisibleRows = localStorage.getItem('visibleRows')
    ? JSON.parse(localStorage.getItem('visibleRows') || '{}')
    : {
        totalGu: true,
        fte: true,
        personnelCosts: true,
        externalCosts: true,
        totalCosts: true,
        rate: true,
        revenues: true,
      };

  const [visibleRows, setVisibleRows] = useState<{ [key: string]: boolean }>(initialVisibleRows);

  return { visibleRows, setVisibleRows };
};

export default useHandleVisibleRows;
