import { useTranslation } from 'react-i18next';
import { getFirstAllocationDate, getLastAllocationDate } from '../../_utils/revenue-plan.utils';
import { StaffExtendedDto } from '@api/client';

interface Props {
  fullAllocationsShown: Partial<StaffExtendedDto>[];
}

const PeriodDateHeader: React.FC<Props> = ({ fullAllocationsShown }) => {
  const { t } = useTranslation();
  return (
    <span className="ms-auto me-5 my-1">
      <span className="mx-1 notification-header"> {t('project-detail.period')} :</span>
      <span className="mx-1">
        <b>{getFirstAllocationDate(fullAllocationsShown)}</b>
      </span>
      -
      <span className="mx-1">
        <b>{getLastAllocationDate(fullAllocationsShown)}</b>
      </span>
    </span>
  );
};

export default PeriodDateHeader;
